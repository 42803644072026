import { Pagination, Spin } from 'antd';
import React from 'react';
import APIMonthlyUsageHistoryFilter from './HistoryFilter';
import SizeBox from '../SizeBox';
import DisplayLastUpdateApp from '../DisplayLastUpdate';
import Icon from '../Icon';
import Text from '../Text';
import TooltipIcon from '../buttons/TooltipIcon';
import { APIKeyUsageI, APIUsageHistoryI, APIUsageHistorySearchParamsI } from '../../interfaces/apiManagement';
import APIUsageHistoryItem from './APIUseageHistoryItem';
import { PAGE_SIZE } from '../../constants';
import EmptyData from '../EmptyData';

const monthCallTooltip = 'Number of times CircularONE API has been called this month';
const sucessfulTooltip = 'Number of successful API Calls';
const errorTooltip =
  'Number of attempts the server has failed to call CircularONE API. These calls will not be counted in your Total Monthly Usage.';

type Props = {
  data?: APIKeyUsageI['apiUsageHistories'];
  currentPage?: number;
  queryParams: APIUsageHistorySearchParamsI;
  refetchData: () => void;
  setIsRefetchLoading: (_: boolean) => void;
  handleFilter: ({ page, per_page }: { page: number; per_page: number }) => void;
  handleFilterChange: (_: APIUsageHistorySearchParamsI) => void;
  setQueryParams: (_: Partial<APIUsageHistorySearchParamsI>) => void;
};

const APICallsHistory: React.FC<Props> = ({
  data,
  queryParams,
  currentPage,
  handleFilter,
  refetchData,
  setIsRefetchLoading,
  setQueryParams,
}) => {
  return (
    <Spin spinning={false}>
      <APIMonthlyUsageHistoryFilter
        queryParams={queryParams}
        refetchData={refetchData}
        setIsRefetchLoading={setIsRefetchLoading}
        setQueryParams={setQueryParams}
      />
      <SizeBox height={16} />
      <div className="tw-flex tw-justify-end tw-w-full">
        <DisplayLastUpdateApp />
      </div>
      <div className="tw-my-6 tw-flex tw-gap-4 sm:tw-flex-col sm:tw-gap-4">
        <div className="tw-flex tw-flex-row tw-gap-3 tw-bg-white tw-border tw-border-solid tw-border-grey-300 tw-rounded-lg tw-p-3 tw-flex-1">
          <div className="tw-bg-white tw-border tw-border-solid tw-border-grey-300 tw-rounded-lg tw-p-3 tw-flex tw-items-center tw-max-w-[66px] tw-max-h-[66px]">
            <Icon name="icon-settings_ethernet" size={40} className="tw-text-grey-800" />
          </div>
          <div className="tw-flex tw-flex-col tw-gap-y-0.5">
            <div className="tw-flex tw-items-center tw-gap-1">
              <Text variant="h4" className="tw-text-grey-700">
                This Month’s API Calls
              </Text>{' '}
              <TooltipIcon text={monthCallTooltip} />
            </div>
            <Text variant="h1">{data?.total ?? 0}</Text>
          </div>
        </div>
        <div className="tw-flex tw-flex-row tw-gap-3 tw-bg-white tw-border tw-border-solid tw-border-grey-300 tw-rounded-lg tw-p-3 tw-flex-1">
          <div className="tw-bg-white tw-border tw-border-solid tw-border-grey-300 tw-rounded-lg tw-p-3 tw-flex tw-items-center tw-max-w-[66px] tw-max-h-[66px]">
            <Icon name="icon-check_circle" size={40} className="tw-text-success-500" />
          </div>
          <div className="tw-flex tw-flex-col tw-gap-y-0.5">
            <div className="tw-flex tw-items-center tw-gap-1">
              <Text variant="h4" className="tw-text-grey-700">
                Successful Calls
              </Text>{' '}
              <TooltipIcon text={sucessfulTooltip} />
            </div>
            <Text variant="h1">{data?.successCount ?? 0}</Text>
          </div>
        </div>
        <div className="tw-flex tw-flex-row tw-gap-3 tw-bg-white tw-border tw-border-solid tw-border-grey-300 tw-rounded-lg tw-p-3 tw-flex-1">
          <div className="tw-bg-white tw-border tw-border-solid tw-border-grey-300 tw-rounded-lg tw-p-3 tw-flex tw-items-center tw-max-w-[66px] tw-max-h-[66px]">
            <Icon name="icon-error_outline" size={40} className="tw-text-error-500" />
          </div>
          <div className="tw-flex tw-flex-col tw-gap-y-0.5">
            <div className="tw-flex tw-items-center tw-gap-1">
              <Text variant="h4" className="tw-text-grey-700">
                Errors
              </Text>{' '}
              <TooltipIcon text={errorTooltip} />
            </div>
            <Text variant="h1">{data ? data.total - data.successCount : 0}</Text>
          </div>
        </div>
      </div>
      {data?.histories.length === 0 && <EmptyData title="No API Call" />}
      {data &&
        data.histories.map((item: APIUsageHistoryI) => {
          return <APIUsageHistoryItem item={item} key={item?.uuid} />;
        })}
      <div className="pagination-container tw-mt-[44px]">
        <Pagination
          total={data?.total ?? 0}
          pageSize={queryParams.limit ?? PAGE_SIZE}
          showTotal={(total) => `${total} Items`}
          current={currentPage}
          onChange={(page, pageSize) => handleFilter({ page, per_page: pageSize })}
          showSizeChanger
        />
      </div>
    </Spin>
  );
};

export default APICallsHistory;
