import React, { useMemo, useState } from 'react';
import Text from '../Text';
import TooltipIcon from '../buttons/TooltipIcon';
import SecondaryButton from '../buttons/SecondaryButton';
import Icon from '../Icon';
import { Spin, Tooltip } from 'antd';
import APICallsHistory from './APICallsHistory';
import { useQuery } from 'react-query';
import { getAPIKeyMonthlyUsage } from '../../apis/apiManagement';
import { displayStartDateOfNextMonth, displayStartEndDateOfMonth, handleFilterParams } from '../../utils/global';
import { Notice } from '../Notice';
import SizeBox from '../SizeBox';
import { PAGE_SIZE } from '../../constants';
import { useNavigate } from 'react-router-dom';
import { APIUsageHistorySearchParamsI } from '../../interfaces/apiManagement';
import ApiUsageIncreaseUsageModal from './IncreaseUsageModal';

const totalMonthlyUsageTooltip =
  'Once you exceed your quota limit, all connections to CircularONE API will be paused until your next month’s reset. You will be notified when you are about to reach the limit';

const APIUsageTab: React.FC = () => {
  const [isIncreaseModalOpen, setIsIncreaseModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const urlSearch = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const queryParams = useMemo(() => {
    const page = urlSearch.get('page') ?? 1;
    const limit = urlSearch.get('limit') ?? PAGE_SIZE;
    const orderType = urlSearch.get('orderType') ?? 'desc';
    const orderBy = urlSearch.get('orderBy') ?? 'createdAt';
    const startDate = urlSearch.get('startDate');
    const endDate = urlSearch.get('endDate');

    return {
      page,
      limit,
      orderType,
      orderBy,
      startDate,
      endDate,
    } as APIUsageHistorySearchParamsI;
  }, [urlSearch]);

  const [isRefetchLoading, setIsRefetchLoading] = useState<boolean>(false);
  const {
    data: apiUsage,
    isFetching,
    refetch: refetchData,
  } = useQuery(['api-management-usage', 'details', queryParams], () => getAPIKeyMonthlyUsage(queryParams));
  const upgradeTooltip = `Upgraded Partnership is granted ${apiUsage?.totalMonthlyUsage.totalLimit} API Calls per month, valid from <b>${displayStartEndDateOfMonth()}</b>`;
  const isAlmostFull = useMemo(() => {
    if (apiUsage) {
      return apiUsage?.totalMonthlyUsage.totalUsed >= apiUsage?.totalMonthlyUsage.totalLimit - 10;
    } else return false;
  }, [apiUsage]);

  const currentPage = queryParams?.page ?? 1;

  const setQueryParams = (params: Partial<APIUsageHistorySearchParamsI>) => {
    const query = handleFilterParams(params);
    navigate(`?${query}`);
  };

  const handleFilterChange = (values: APIUsageHistorySearchParamsI) => {
    setQueryParams({ ...queryParams, ...values });
  };

  const handleFilter = (params: { page: number; per_page: number }) => {
    setQueryParams({ ...queryParams, page: params.page, limit: params.per_page });
  };

  const onIncreaseUsageClick = () => {
    setIsIncreaseModalOpen(true);
  };

  return (
    <Spin spinning={isFetching ?? isRefetchLoading}>
      <div className="tw-bg-white tw-border-primary tw-rounded-[10px] tw-p-6 tw-flex-col tw-flex tw-mb-8">
        <span className="tw-flex tw-items-center tw-gap-3">
          <Text variant="h3">Total Monthly Usage</Text>
          <TooltipIcon buttonClassName="tw-w-5 tw-h-5" text={totalMonthlyUsageTooltip} />
        </span>
        <Text variant="labelMd" className="!tw-font-normal tw-mt-1">
          {displayStartEndDateOfMonth()}
        </Text>
        <div className="tw-flex tw-justify-between tw-mt-3 sm:tw-flex-col sm:tw-gap-3">
          <div className="tw-flex tw-items-center tw-gap-3">
            <Text variant="h1">
              <span
                style={{
                  color:
                    apiUsage?.totalMonthlyUsage.totalUsed !== apiUsage?.totalMonthlyUsage.totalLimit
                      ? '#191919'
                      : '#B13939',
                }}
              >
                {apiUsage?.totalMonthlyUsage.totalUsed}
              </span>{' '}
              / {apiUsage?.totalMonthlyUsage.totalLimit}
            </Text>
            {apiUsage && apiUsage?.totalMonthlyUsage.totalLimit > 100 && (
              <Tooltip placement="right" title={<span dangerouslySetInnerHTML={{ __html: upgradeTooltip }} />}>
                <div className="tw-border-primary !tw-border-secondary-200 tw-rounded tw-inline-flex tw-items-center tw-px-2 tw-py-1">
                  <Icon name="icon-star" className="tw-text-secondary-500" size={12} />
                  <Text variant="labelMd" className="tw-text-secondary-500 tw-ml-1">
                    Upgraded
                  </Text>
                </div>
              </Tooltip>
            )}
          </div>
          <SecondaryButton
            label="Increase Usage"
            icon={<Icon name="icon-unarchive" />}
            onClick={onIncreaseUsageClick}
          />
        </div>
        <div className="tw-relative tw-w-full tw-h-4 tw-bg-grey-400 tw-rounded-full tw-my-3">
          <span
            className="tw-absolute tw-left-0 tw-top-0 tw-rounded-full tw-h-full"
            style={{
              width:
                apiUsage?.totalMonthlyUsage.totalUsed !== 0
                  ? `${((apiUsage?.totalMonthlyUsage.totalUsed ?? 1) / (apiUsage?.totalMonthlyUsage.totalLimit ?? 1)) * 100}%`
                  : '0%',
              background: isAlmostFull ? '#B13939' : '#0a7a08',
            }}
          />
        </div>
        <Text variant="bodyMd">
          API usage will be reset monthly. Next reset is{' '}
          <span className="tw-font-bold">{displayStartDateOfNextMonth()}</span>
        </Text>
        {apiUsage?.totalMonthlyUsage.totalUsed === apiUsage?.totalMonthlyUsage.totalLimit && (
          <>
            <SizeBox height={24} />
            <Notice
              type="warning"
              message="Total Monthly Usage is about to exceed!"
              description="Increase your API monthly usage so that your usage is not interrupted."
              closable={false}
            />
          </>
        )}
      </div>
      <div className="tw-bg-white tw-border-primary tw-rounded-[10px] tw-p-6 tw-gap-3 tw-flex-col tw-flex">
        <APICallsHistory
          data={apiUsage?.apiUsageHistories}
          handleFilter={handleFilter}
          handleFilterChange={handleFilterChange}
          queryParams={queryParams}
          currentPage={currentPage}
          setIsRefetchLoading={setIsRefetchLoading}
          refetchData={refetchData}
          setQueryParams={setQueryParams}
        />
      </div>
      <ApiUsageIncreaseUsageModal isModalVisible={isIncreaseModalOpen} setIsModalVisible={setIsIncreaseModalOpen} />
    </Spin>
  );
};

export default APIUsageTab;
