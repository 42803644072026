import React, { ReactNode, useEffect } from 'react';
import { Button, Form, Input, message, Modal } from 'antd';
import CheckCircleIcon from '../../svgs/CheckCircleIcon';
import Text from '../Text';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Icon from '../Icon';
import SecondaryButton from '../buttons/SecondaryButton';
import { APIRedemptionI } from '../../interfaces/apiRedemption';
import { DateFormat } from '../../utils/global';

type Props = {
  data?: APIRedemptionI;
  isModalVisible: boolean;
  setIsModalVisible: (_: boolean) => void;
};

const RedeemtionSuccessModal: React.FC<Props> = ({ isModalVisible, data, setIsModalVisible }) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data]);

  const renderView = (label: string, value: ReactNode) => {
    return (
      <>
        <div className="tw-flex tw-flex-col tw-mb-6">
          <Text variant="bodyLg" className="tw-block tw-text-grey-900">
            {label}
          </Text>
          <Text variant="bodyLgB" className="tw-block tw-text-grey-900">
            {value}
          </Text>
        </div>
      </>
    );
  };

  return (
    <Modal open={isModalVisible} onOk={handleCancel} onCancel={handleCancel} footer={null} width={555}>
      <div className="tw-flex tw-flex-col tw-items-center tw tw-py-4 tw-px-4">
        <CheckCircleIcon width={48} height={48} color="#0A7A08" />
        <Text variant="h3" className="tw-mt-2 tw-flex tw-flex-col tw-items-center">
          <span className="tw-block">You have successfully generated </span>
          <span>Redemption Code!</span>
        </Text>
      </div>
      <div className="notice tw-mb-8 tw-text-grey-700">
        <Form form={form} requiredMark={false} scrollToFirstError autoComplete="off" layout="vertical">
          <div className="form-container">
            <div className="form-content">
              <Form.Item label="Redemption Code" name="code" className="tw-w-full !tw-border-grey-500">
                <Input
                  suffix={
                    <CopyToClipboard text={data?.code ?? ''} onCopy={() => message.info('Copied')}>
                      <Button className="tw-border-none tw-p-0">
                        <Icon name="icon-file_copy" size={20} className="tw-text-primary-500" />
                      </Button>
                    </CopyToClipboard>
                  }
                  disabled
                />
              </Form.Item>
            </div>
          </div>
        </Form>
        {renderView('Code Expiry Date', data?.expiryDate ? DateFormat(data?.expiryDate) : '-')}
        <div>
          <Text variant="bodyLg" className="tw-block tw-text-grey-800">
            Monthly API Usage is upgraded as following:
          </Text>
          <div className="tw-border-primary tw-rounded tw-bg-grey-200 tw-p-4">
            {renderView('New Monthly API Usage', `100 → ${data?.newLimit}`)}
            {renderView('Validity Duration', `${data?.duration} months`)}
            {renderView('Reserved for', data?.validPartner ? data?.validPartner.displayName : '-')}
          </div>
          <SecondaryButton
            label="Close"
            className="tw-w-full tw-bg-white !tw-border-grey-300 tw-text-grey-900 tw-mt-9"
            onClick={handleCancel}
          />
        </div>
      </div>
    </Modal>
  );
};

export default RedeemtionSuccessModal;
