import React from 'react';
import { InputNumber } from 'antd';
import Icon from '../Icon';
import './EInputNumber.scss';

type Props = {
  min?: number | null;
  max?: number | null;
  step?: number;
  suffix?: string;
  placeholder?: string;
  onChange?: (value: number | null) => void;
};

const EInputNumber: React.FC<Props> = ({
  min = 5,
  max = 4500,
  step = 1,
  suffix,
  placeholder = 'Enter a number',
  onChange,
  ...props
}) => {
  return (
    <>
      <InputNumber
        {...props}
        min={min === null ? undefined : min}
        max={max === null ? undefined : max}
        className="e-input-number-container tw-w-full"
        controls={{
          upIcon: <Icon name="icon-add_circle_outline" size={28} />,
          downIcon: <Icon name="icon-remove_circle_outline" size={28} />,
        }}
        step={step}
        suffix={suffix ?? ''}
        placeholder={placeholder}
        onChange={onChange}
      />
    </>
  );
};

export default EInputNumber;
