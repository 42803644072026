import { Modal } from 'antd';
import React, { useState } from 'react';
import GenerateKeyName from '../forms/APIManagement/GenerateKeyName';
import GenerateAPIKeysForm from '../forms/APIManagement/GenerateAPIKeys';
import { useMutation, useQueryClient } from 'react-query';
import { generateApiKey } from '../../apis/apiManagement';
import { GenerateAPIKeyResponseI } from '../../interfaces/apiManagement';
import ErrorModalIcon from '../../svgs/ErrorModalIcon';

type FormValue = {
  name: string;
};

type Props = {
  isModalVisible: boolean;
  setIsModalVisible: (_: boolean) => void;
  setCurrentTab?: (_: string) => void;
};

const GenerateAPIKey: React.FC<Props> = ({ isModalVisible, setIsModalVisible, setCurrentTab }) => {
  const [step, setStep] = useState(1);
  const [isGenerateKey, setIsGenerateKey] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const [successData, setSuccessData] = useState<GenerateAPIKeyResponseI>();
  const modal = Modal;

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsGenerateKey(false);
  };

  const { mutate: createMutate } = useMutation(generateApiKey, {
    onSuccess: async (data) => {
      setSuccessData(data);
      queryClient.invalidateQueries();
      if (data) {
        setStep(2);
        setIsModalVisible(false);
        setIsGenerateKey(true);
      }
    },
    onError: () => {
      const errorModal = modal.error({
        type: 'error',
        title: 'Failed to generate a new API Key!',
        content: 'The system has failed to generate an API Key, please try again.',
        className: 'context-modal-custom-container',
        closable: true,
        footer: null,
        icon: <ErrorModalIcon color="#B13939" />,
        width: 345,
      });

      setTimeout(() => {
        errorModal.destroy();
      }, 5000);
    },
  });

  const handleGenerateName = (values: FormValue) => {
    createMutate(values);
  };

  return (
    <>
      {step === 1 && (
        <Modal open={isModalVisible} onOk={handleCancel} onCancel={handleCancel} footer={null} width={469}>
          <GenerateKeyName handleSubmit={handleGenerateName} />
        </Modal>
      )}
      {step === 2 && (
        <Modal
          open={isGenerateKey}
          onOk={handleCancel}
          onCancel={handleCancel}
          footer={null}
          width={555}
          keyboard={false}
          closable={false}
        >
          <GenerateAPIKeysForm data={successData} setCurrentTab={setCurrentTab} setIsGenerateKey={setIsGenerateKey} />
        </Modal>
      )}
    </>
  );
};

export default GenerateAPIKey;
