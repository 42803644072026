import { Checkbox, Form } from 'antd';
import React, { Fragment, useMemo } from 'react';
import ActionSection from '../../Role/ActionSection';
import { ModuleI } from '../../../interfaces/module';
import { CheckboxOptionI } from '../../../interfaces';
import { handleGetPermissionFromModule } from '../../../utils/role';

type Props = {
  modules?: ModuleI[];
  onCheckboxChange: (values: string[], colName: string) => void;
};

const PlatformCollapse: React.FC<Props> = ({ modules, onCheckboxChange }) => {
  const partnerCheckboxOptions: CheckboxOptionI[] = useMemo(
    () => handleGetPermissionFromModule(modules, 'Platform_partner'),
    [modules],
  );
  const siteCheckboxOptions: CheckboxOptionI[] = useMemo(
    () => handleGetPermissionFromModule(modules, 'Platform_site'),
    [modules],
  );
  const hardwareCheckboxOptions: CheckboxOptionI[] = useMemo(
    () => handleGetPermissionFromModule(modules, 'Platform_hardware'),
    [modules],
  );
  const productCheckboxOptions: CheckboxOptionI[] = useMemo(
    () => handleGetPermissionFromModule(modules, 'Platform_product'),
    [modules],
  );

  const apiRedemptionCodeOptions: CheckboxOptionI[] = useMemo(
    () => handleGetPermissionFromModule(modules, 'Platform_apiRedemptionCode'),
    [modules],
  );

  return (
    <Fragment>
      <ActionSection
        label="All Partner"
        content={
          <Form.Item name="colPlatform_partner">
            <Checkbox.Group
              options={partnerCheckboxOptions}
              onChange={(e) => onCheckboxChange(e, 'colPlatform_partner')}
            />
          </Form.Item>
        }
      />
      <ActionSection
        label="All Sites"
        content={
          <Form.Item name="colPlatform_site">
            <Checkbox.Group options={siteCheckboxOptions} onChange={(e) => onCheckboxChange(e, 'colPlatform_site')} />
          </Form.Item>
        }
      />
      <ActionSection
        label="All Hardware"
        content={
          <Form.Item name="colPlatform_hardware">
            <Checkbox.Group
              options={hardwareCheckboxOptions}
              onChange={(e) => onCheckboxChange(e, 'colPlatform_hardware')}
            />
          </Form.Item>
        }
      />
      <ActionSection
        label="All Products"
        content={
          <Form.Item name="colPlatform_product">
            <Checkbox.Group
              options={productCheckboxOptions}
              onChange={(e) => onCheckboxChange(e, 'colPlatform_product')}
            />
          </Form.Item>
        }
      />
      <ActionSection
        label="API Redemption Code"
        content={
          <Form.Item name="colPlatform_apiRedemptionCode">
            <Checkbox.Group
              options={apiRedemptionCodeOptions}
              onChange={(e) => onCheckboxChange(e, 'colPlatform_apiRedemptionCode')}
            />
          </Form.Item>
        }
      />
    </Fragment>
  );
};

export default PlatformCollapse;
