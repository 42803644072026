import React, { useMemo, useState } from 'react';
import Text from '../../components/Text';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import PlusIcon from '../../svgs/PlusIcon';
import APIRedemptionsTable from '../../components/tables/APIRedemptions';
import GenerateModal from '../../components/APIRedemption/GenerateModal';
import { CurrentUserI } from '../../interfaces/auth';
import { useLocation, useOutletContext } from 'react-router-dom';
import { handleAccessControl } from '../../utils/role';
import { PAGE_SIZE } from '../../constants';
import { APIRedemptionI, APIRedemptionSearchParamsI } from '../../interfaces/apiRedemption';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { deleteApiRedemption, getPlatformAPIRedemptionsList } from '../../apis/platforms/apiRedemption';
import { Button, Dropdown, message } from 'antd';
import RedeemtionSuccessModal from '../../components/APIRedemption/SuccessModal';
import DangerButton from '../../components/buttons/DangerButton';

const APIRedemptionsPage: React.FC = () => {
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'platform/api-redemptions');
  const queryClient = useQueryClient();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
  const [successData, setSuccessData] = useState<APIRedemptionI>();
  const [editData, setEditData] = useState<APIRedemptionI>();
  const location = useLocation();
  const urlSearch = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const queryParams = useMemo(() => {
    const page = urlSearch.get('page') ?? 1;
    const limit = urlSearch.get('limit') ?? PAGE_SIZE;
    const orderType = urlSearch.get('orderType') ?? 'desc';
    const orderBy = urlSearch.get('orderBy') ?? 'createdAt';
    const search = urlSearch.get('search');
    const filterStatus = urlSearch.get('filter[status]');
    const viewMode = urlSearch.get('viewMode');

    return {
      page,
      limit,
      orderType,
      orderBy,
      viewMode,
      filter: { status: filterStatus },
      search,
    } as APIRedemptionSearchParamsI & { viewMode?: string };
  }, [urlSearch]);

  const { data: { apiRedemptionCodes = [], total = 0 } = {}, isFetching } = useQuery(
    ['api-redemptions', 'list', queryParams],
    () => getPlatformAPIRedemptionsList(queryParams),
  );

  const currentPage = queryParams?.page;
  const pagination: any = {
    current: currentPage,
    pageSize: queryParams.limit ?? PAGE_SIZE,
    total: total,
    showTotal: (total: string) => `${total} Items`,
  };

  const { mutate: updateMutate } = useMutation(deleteApiRedemption, {
    onSuccess: async () => {
      message.info('Update successfully!');
      queryClient.invalidateQueries();
    },
    onError: () => {
      message.error('Delete failed! Please try again');
    },
  });

  const handleDeleteItem = (record: APIRedemptionI) => {
    updateMutate(record.uuid);
  };

  const onEditClick = (record: APIRedemptionI) => {
    setIsModalVisible(true);
    setEditData(record);
  };

  const withAction = (record: any) => {
    return (
      <span className="action" style={{ display: 'flex' }}>
        <Dropdown.Button
          overlayClassName="table-action-menu"
          menu={{
            items: [
              access.isCanUpdate
                ? {
                    key: '1',
                    label: (
                      <Button
                        className="btn-table-action-primary"
                        type="link"
                        disabled={record?.status !== 'pending'}
                        onClick={() => onEditClick(record)}
                      >
                        Edit Redemption Code
                      </Button>
                    ),
                  }
                : null,
              access.isCanUpdateStatus
                ? {
                    key: '2',
                    label: (
                      <DangerButton
                        label="Delete"
                        onOk={() => handleDeleteItem(record)}
                        className="hover:!tw-bg-inherit"
                      />
                    ),
                  }
                : null,
            ],
          }}
        ></Dropdown.Button>
      </span>
    );
  };

  return (
    <>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-p-4 tw-bg-grey-50">
        <div className="tw-flex-row tw-flex tw-items-center tw-justify-between tw-gap-4 tw-mb-8">
          <Text component="h3" variant="h3">
            API Redemption Code
          </Text>
          {access.isCanCreate && (
            <div className="page-header-actions">
              <PrimaryButton label="Generate Code" icon={<PlusIcon />} onClick={() => setIsModalVisible(true)} />
            </div>
          )}
        </div>
        <APIRedemptionsTable
          rowKey="uuid"
          loading={isFetching}
          pagination={pagination}
          dataSource={apiRedemptionCodes}
          withAction={withAction}
        />
      </div>
      <GenerateModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        setIsSuccessModalOpen={setIsSuccessModalOpen}
        setSuccessData={setSuccessData}
        data={editData}
        setEditData={setEditData}
      />
      <RedeemtionSuccessModal
        isModalVisible={isSuccessModalOpen}
        setIsModalVisible={setIsSuccessModalOpen}
        data={successData}
      />
    </>
  );
};

export default APIRedemptionsPage;
