import { Checkbox, Spin } from 'antd';
import React, { useMemo } from 'react';
import ActionSection from './ActionSection';
import { ModuleI } from '../../interfaces/module';
import { RoleI } from '../../interfaces/role';
import { CheckboxOptionI } from '../../interfaces';
import { handleGetPermissionFromModule } from '../../utils/role';

type Props = {
  modules?: ModuleI[];
  role?: RoleI;
};

const RolePlatformCollapseDetail: React.FC<Props> = ({ modules, role }) => {
  const partnerCheckboxOptions: CheckboxOptionI[] = useMemo(
    () => handleGetPermissionFromModule(modules, 'Platform_partner'),
    [modules],
  );
  const siteCheckboxOptions: CheckboxOptionI[] = useMemo(
    () => handleGetPermissionFromModule(modules, 'Platform_site'),
    [modules],
  );
  const hardwareCheckboxOptions: CheckboxOptionI[] = useMemo(
    () => handleGetPermissionFromModule(modules, 'Platform_hardware'),
    [modules],
  );
  const productCheckboxOptions: CheckboxOptionI[] = useMemo(
    () => handleGetPermissionFromModule(modules, 'Platform_product'),
    [modules],
  );
  const apiRedemptionCodeOptions: CheckboxOptionI[] = useMemo(
    () => handleGetPermissionFromModule(modules, 'Platform_apiRedemptionCode'),
    [modules],
  );

  return (
    <Spin spinning={!role}>
      <ActionSection
        label="All Partners"
        content={<Checkbox.Group options={partnerCheckboxOptions} value={role?.colPlatform_partner} />}
      />
      <ActionSection
        label="All Sites"
        content={<Checkbox.Group options={siteCheckboxOptions} value={role?.colPlatform_site} />}
      />
      <ActionSection
        label="All Hardware"
        content={<Checkbox.Group options={hardwareCheckboxOptions} value={role?.colPlatform_hardware} />}
      />
      <ActionSection
        label="All Products"
        content={<Checkbox.Group options={productCheckboxOptions} value={role?.colPlatform_product} />}
      />
      <ActionSection
        label="API Redemption Code"
        content={<Checkbox.Group options={apiRedemptionCodeOptions} value={role?.colPlatform_apiRedemptionCode} />}
      />
    </Spin>
  );
};

export default RolePlatformCollapseDetail;
