import React, { ReactNode } from 'react';
import EmptyDataImage from '../images/EmptyData.png';
import './EmptyData.scss';
import Text from './Text';
import { Link } from 'react-router-dom';

type Props = {
  message?: string;
  title?: string;
  button?: {
    title: string;
    url?: string;
    buttonFunction?: () => void;
    type?: 'primary' | 'secondary';
    icon?: ReactNode;
  };
  imageSize?: { width: string; height: string };
};

const EmptyData: React.FC<Props> = ({ message, title, button, imageSize }) => {
  return (
    <div className="component-empty-data-container">
      <div className="tw-flex tw-flex-col tw-items-center tw-gap-3">
        <img src={EmptyDataImage} alt="Data is empty" style={imageSize} />
        {title && <Text variant="h3">{title}</Text>}
        {message && (
          <Text variant="bodyMd" className="tw-text-grey-700 tw-max-w-[288px] tw-text-center">
            {message}
          </Text>
        )}
        {button && button?.url && (
          <Link
            to={button.url ?? '#'}
            className="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-p-3 tw-bg-white tw-rounded-lg tw-min-w-[236px] tw-border tw-border-solid tw-border-success-primary tw-text-center"
            style={{
              backgroundColor: button.type === 'primary' ? '#64AA4B' : '#fff',
              color: button.type === 'primary' ? '#fff' : '#191919',
            }}
          >
            {button?.icon}
            {button.title}
          </Link>
        )}
        {button && button?.buttonFunction && (
          <button
            onClick={button.buttonFunction}
            className="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-p-3 tw-bg-white tw-rounded-lg tw-min-w-[236px] tw-border tw-border-solid tw-border-success-primary tw-text-center"
            style={{
              backgroundColor: button.type === 'primary' ? '#64AA4B' : '#fff',
              color: button.type === 'primary' ? '#fff' : '#191919',
            }}
          >
            {button?.icon}
            {button.title}
          </button>
        )}
      </div>
    </div>
  );
};

export default EmptyData;
