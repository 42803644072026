import {
  HardwareGroupCollectionI,
  HardwareGroupI,
  HardwareGroupParamsI,
  HardwareGroupSearchParamsI,
  HardwareGroupTypeI,
  HardwareHistoriesCollectionI,
  HardwareRegisterParamsI,
  HardwareScannedStateI,
  HardwareUpdateStatusI,
  HardwareVerifyCollectionI,
  HardwareVerifyTypeI,
} from './../interfaces/hardware';
import { BaseResponse } from '../interfaces';
import { HardwareCollectionI, HardwareI, HardwareSearchParamsI } from '../interfaces/hardware';
import { handleFilterParams } from '../utils/global';
import { api } from './api';
import { useFetch } from './reactQuery';
import { SiteCollectionI, SiteSearchParamsI } from '../interfaces/site';
import { ProductI } from '../interfaces/product';
import { HardwareHistorySearchParams } from '../interfaces/hardwareHistory';
import { HardwareFaultReportCollectionI, HardwareFaultReportSearchParams } from '../interfaces/hardwareFaultReports';

export const useGetHardware = (id?: number | string) => {
  const { data: response, error, isFetching, refetch } = useFetch<any>(`hardwares/${id}`);
  const data = response?.data;
  return { data, error, isFetching, refetch };
};

export const getHardwareDetails = async (id: number | string) => {
  const { data: response } = await api.get<BaseResponse<HardwareI>>(`hardwares/${id}`);
  return response.data;
};

export const getHardwaresList = async (params?: HardwareSearchParamsI) => {
  const { data: response } = await api.get<BaseResponse<HardwareCollectionI>>(
    `hardwares?${handleFilterParams(params)}`,
  );
  return response;
};

export const getHardwareRegistrationsList = async (params?: HardwareSearchParamsI) => {
  const { data: response } = await api.get<BaseResponse<any>>(`registration-histories?${handleFilterParams(params)}`);
  return response;
};

export const createHardwares = async (values: HardwareI) => {
  const { data: response }: any = await api.post('hardwares', values);
  return response.data;
};

export const doRegisterHardware = async (values: HardwareRegisterParamsI) => {
  const { data: response }: any = await api.post('hardwares/registration', values);
  return response.data;
};

export const updateHardwares = async ({ id, values }: { id: string; values: Partial<HardwareI> }) => {
  const { data: response }: any = await api.patch(`hardwares/${id}`, values);
  return response.data;
};

export const doVerifyHardware = async (params: {
  hardwareList: HardwareScannedStateI[];
  verifyType: HardwareVerifyTypeI;
}) => {
  const { data: response }: any = await api.post<BaseResponse<HardwareVerifyCollectionI>>(`hardwares/verify`, params);
  return response.data;
};

export const getHardwareSitesList = async (params?: SiteSearchParamsI) => {
  const { data: response } = await api.get<BaseResponse<SiteCollectionI>>(`me/sites?${handleFilterParams(params)}`);
  return response;
};

export const getHardwareProductBySKU = async (sku: string) => {
  const { data: response } = await api.get<BaseResponse<ProductI>>(`products/sku/${sku}`);
  return response?.data;
};

export const updateHardwareStatus = async (newParams: { id: string; params: HardwareUpdateStatusI }) => {
  const { data: response }: any = await api.patch(`hardwares/${newParams?.id}/status`, { ...newParams.params });
  return response.data;
};

export const doVerifyHardwareGroup = async (params: {
  hardwareList: HardwareScannedStateI[];
  hardwareType: HardwareGroupTypeI;
  toteVolume?: string;
  siteId: number;
}) => {
  const { data: response }: any = await api.post<BaseResponse<HardwareVerifyCollectionI>>(
    `hardware-groups/verify`,
    params,
  );
  return response.data;
};

export const doAddHardwareGroup = async (values: HardwareGroupParamsI) => {
  const { data: response }: any = await api.post('hardware-groups', values);
  return response.data;
};

export const getHardwareGroupsList = async (params?: HardwareGroupSearchParamsI) => {
  const { data: response } = await api.get<BaseResponse<HardwareGroupCollectionI>>(
    `hardware-groups?${handleFilterParams(params)}`,
  );
  return response;
};

export const useGetHardwareGroup = (code?: string) => {
  const { data: response, error, isLoading } = useFetch<any>(`hardware-groups/${code}`);
  const data: HardwareGroupI = response?.data;
  return { data, error, isLoading };
};

export const updateHardwareGroup = async (params: { code?: string | number; data: HardwareGroupParamsI }) => {
  const { data: response }: any = await api.patch(`hardware-groups/${params.code}`, params.data);
  return response.data;
};

export const getHardwareHistory = async (params: { id: string; params: HardwareHistorySearchParams }) => {
  const { data: response } = await api.get<BaseResponse<HardwareHistoriesCollectionI>>(
    `hardwares/${params?.id}/histories?${handleFilterParams(params?.params)}`,
  );
  return response;
};

export const getHardwareModel = async (productId: string) => {
  const { data: response } = await api.get<BaseResponse<HardwareI>>(`tuya/tuyaProduct/${productId}`);
  return response.data;
};

export const getHardwareFaultReports = async (params: {
  serialCode: string;
  params: HardwareFaultReportSearchParams;
}) => {
  const { data: response } = await api.get<BaseResponse<HardwareFaultReportCollectionI>>(
    `hardwares/${params.serialCode}/fault-reports?${handleFilterParams(params?.params)}`,
  );
  return response;
};

export const scanHardwareWorkflow = async (serialCode: string) => {
  const { data: response } = await api.get<BaseResponse<HardwareI>>(`hardwares/scan/${serialCode}`);
  return response.data;
};
