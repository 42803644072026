import React from 'react';
import Text from '../Text';
import { Radio, Space } from 'antd';
import Icon from '../Icon';
import { IconName } from '../Icon/iconName';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';

type Props = {
  type: 'ecotote' | 'all';
  hardwareTypes?: { label: string; value: string }[];
  onChange: (_: any) => void;
  onConfirmClick: () => void;
  onBackClick: () => void;
};

const HardwareChooseType: React.FC<Props> = ({ hardwareTypes, type, onChange, onBackClick, onConfirmClick }) => {
  const renderLabel = (icon: IconName, label: string, value: string) => {
    return (
      <Radio value={value} className="tw-p-3 tw-flex tw-items-center">
        <span className="tw-flex tw-items-center tw-m-0 tw-gap-[2px]">
          <Icon name={icon} size={20} />
          <Text variant="labelMd">{label}</Text>
        </span>
      </Radio>
    );
  };

  return (
    <div>
      <Text variant="h3" className="tw-mb-2">
        Choose a Hardware Type
      </Text>
      <Text variant="bodyMd" className="tw-mb-6">
        Hardware Grouping only allow the same Hardware Type to be grouped. Please choose 1 Hardware Type for Grouping.
      </Text>
      <Radio.Group
        onChange={onChange}
        value={hardwareTypes?.[0] && hardwareTypes[0] ? hardwareTypes[0].value : 'ecotote4.5l'}
      >
        <Space direction="vertical">
          {type === 'ecotote' && hardwareTypes ? (
            hardwareTypes?.map((item) => (
              <Radio key={item.value} value={item.value} className="tw-p-3 tw-flex tw-items-center">
                <span className="tw-flex tw-items-center tw-m-0 tw-gap-[2px]">
                  <Icon name="icon-eco-tote" size={20} />
                  <Text variant="labelMd">{item.label}</Text>
                </span>
              </Radio>
            ))
          ) : (
            <>
              {renderLabel('icon-eco-tote', 'ecoTOTE 4.50L', 'ecotote4.5l')}
              {renderLabel('icon-eco-tote', 'ecoTOTE 1.75L', 'ecotote1.75l')}
            </>
          )}
          {type === 'all' && (
            <>
              {renderLabel('icon-smart-pour', 'SmartPour', 'smartpour')}
              {renderLabel('icon-eco-plant', 'ecoPLANT', 'ecoplant')}
            </>
          )}
        </Space>
      </Radio.Group>
      <PrimaryButton label="Confirm" className="tw-w-full tw-mb-3 tw-mt-6" onClick={onConfirmClick} />
      <SecondaryButton label="Back" className="tw-w-full" onClick={onBackClick} />
    </div>
  );
};

export default HardwareChooseType;
