import React from 'react';
import { DateTimeFormat } from '../../utils/global';
import Divider from '../Divider';
import SizeBox from '../SizeBox';
import Text from '../Text';
import { APIUsageHistoryI } from '../../interfaces/apiManagement';

type Props = {
  item: APIUsageHistoryI;
};

const APIUsageHistoryItem: React.FC<Props> = ({ item }) => {
  return (
    <div className="tw-p-4">
      <div className="tw-flex tw-justify-between tw-flex-wrap tw-mb-3 tw-items-center">
        <Text variant="labelMd" className="tw-text-grey-700">
          <span className="tw-inline-block tw-py-1 tw-px-2 tw-text-success-500 tw-rounded tw-bg-grey-200 tw-border-primary">
            {item.type}
          </span>{' '}
          API Call
        </Text>
        <Text variant="bodyMd" className="tw-text-grey-800">
          {DateTimeFormat(item?.createdAt)}
        </Text>
      </div>
      <div className="tw-flex tw-items-center tw-gap-2 tw-mb-3 tw-flex-wrap">
        {item && item?.type === 'success' && (
          <Text variant="bodyLg" className="tw-flex tw-flex-wrap tw-items-center">
            <b>{item.apiKey.name} </b>&nbsp; used on &nbsp;<b>{item.uriPath}</b> &nbsp; with &nbsp;{' '}
            <b>{item.httpCode}</b>
          </Text>
        )}
        {item && item?.type === 'error' && (
          <Text variant="bodyLg" className="tw-flex tw-flex-wrap tw-items-center">
            <span className="tw-text-primary-800">{item.apiKey.name} </span>&nbsp; used on &nbsp;<b>{item.uriPath}</b>{' '}
            &nbsp; failed to be called due to &nbsp;{' '}
            <span className="tw-text-error-500">
              {item.httpCode}&nbsp; {item.description}
            </span>
          </Text>
        )}
      </div>
      <SizeBox height={16} />
      <Divider />
    </div>
  );
};

export default APIUsageHistoryItem;
