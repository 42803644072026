import { GenerateRedemptionCodeRequestT } from './../../interfaces/apiRedemption';
import { BaseResponse } from '../../interfaces';
import { APIRedemptionI, APIRedemptionsCollectionI, APIRedemptionSearchParamsI } from '../../interfaces/apiRedemption';
import { handleFilterParams } from '../../utils/global';
import { api } from '../api';
import { useFetch } from '../reactQuery';

export const useGetPlatformAPIRedemption = (uuid?: string) => {
  const { data: response, error, isFetching } = useFetch<any>(`platform/api-redemption-codes/${uuid}`);
  const data: APIRedemptionI = response?.data;
  return { data, error, isFetching };
};

export const getPlatformAPIRedemptionDetails = async (sku?: string) => {
  const { data: response } = await api.get<BaseResponse<APIRedemptionI>>(`platform/api-redemption-codes/${sku}`);
  return response?.data;
};

export const getPlatformAPIRedemptionsList = async (params?: APIRedemptionSearchParamsI) => {
  const { data: response } = await api.get<BaseResponse<APIRedemptionsCollectionI>>(
    `platform/api-redemption-codes?${handleFilterParams(params)}`,
  );
  return response?.data;
};

export const createPlatformApiRedemption = async (values: GenerateRedemptionCodeRequestT) => {
  const { data: response } = await api.post<{ data: APIRedemptionI }>('platform/api-redemption-codes', values);
  return response.data;
};

export const updatePlatformApiRedemption = async (params: { uuid: string; values: GenerateRedemptionCodeRequestT }) => {
  const { data: response } = await api.patch<{ data: APIRedemptionI }>(
    `platform/api-redemption-codes/${params.uuid}`,
    params.values,
  );
  return response.data;
};

export const deleteApiRedemption = async (uuid: string) => {
  const { data: response }: any = await api.patch(`platform/api-redemption-codes/${uuid}/status`, {});
  return response.data;
};
