import { Checkbox, Form } from 'antd';
import React, { useMemo } from 'react';
import { ModuleI } from '../../../interfaces/module';
import { CheckboxOptionI } from '../../../interfaces';
import ActionSection from '../../Role/ActionSection';
import { handleGetPermissionFromModule } from '../../../utils/role';

type Props = {
  modules?: ModuleI[];
  onCheckboxChange: (values: string[], colName: string) => void;
};

const IntegrationCollapse: React.FC<Props> = ({ modules, onCheckboxChange }) => {
  const options: CheckboxOptionI[] = useMemo(() => handleGetPermissionFromModule(modules, 'ApiManagement'), [modules]);

  return (
    <ActionSection
      label="API Management"
      content={
        <Form.Item name="colApiManagement">
          <Checkbox.Group options={options} onChange={(e) => onCheckboxChange(e, 'colApiManagement')} />
        </Form.Item>
      }
    />
  );
};

export default IntegrationCollapse;
