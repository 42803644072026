import React from 'react';
import { Form, message, Modal } from 'antd';
import GenerateRedemptionCodeForm from '../forms/APIRedemption/Generate';
import { GenerateRedemptionCodeRequestT, APIRedemptionI } from '../../interfaces/apiRedemption';
import { useMutation, useQueryClient } from 'react-query';
import { createPlatformApiRedemption, updatePlatformApiRedemption } from '../../apis/platforms/apiRedemption';

type Props = {
  data?: APIRedemptionI;
  setEditData?: (_?: APIRedemptionI) => void;
  setSuccessData: (_: APIRedemptionI) => void;
  isModalVisible: boolean;
  setIsModalVisible: (_: boolean) => void;
  setIsSuccessModalOpen: (_: boolean) => void;
};

const GenerateModal: React.FC<Props> = ({
  data,
  isModalVisible,
  setIsModalVisible,
  setIsSuccessModalOpen,
  setSuccessData,
  setEditData,
}) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
    setEditData && setEditData(undefined);
  };

  const { mutate: createMutate } = useMutation(createPlatformApiRedemption, {
    onSuccess: async (data) => {
      message.info('Create successfully!');
      queryClient.invalidateQueries({
        queryKey: ['api-redemptions', 'list'],
      });
      setIsSuccessModalOpen(true);
      setSuccessData(data);
      handleCancel();
    },
    onError: () => {
      message.error('Create failed! Please try again');
    },
  });

  const { mutate: updateMutate } = useMutation(updatePlatformApiRedemption, {
    onSuccess: () => {
      message.info('Update successfully!');
      queryClient.invalidateQueries({
        queryKey: ['api-redemptions', 'list'],
      });
      handleCancel();
    },
    onError: () => {
      message.error('Update failed! Please try again');
    },
  });

  const handleSubmit = (values: GenerateRedemptionCodeRequestT) => {
    if (data) {
      updateMutate({ uuid: data.uuid, values });
    } else {
      createMutate(values);
    }
  };

  return (
    <Modal open={isModalVisible} onOk={handleCancel} onCancel={handleCancel} footer={null} width={555}>
      <GenerateRedemptionCodeForm form={form} handleSubmit={handleSubmit} handleCancel={handleCancel} data={data} />
    </Modal>
  );
};

export default GenerateModal;
