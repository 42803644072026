import React from 'react';
import TableBase, { Props as TableProps } from './Base';
import { DateFormat, displayStringWithStar } from '../../utils/global';
import { APIRedemptionI } from '../../interfaces/apiRedemption';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Button, message } from 'antd';
import Icon from '../Icon';
import dayjs from 'dayjs';
import Avatar from '../User/Avatar';
import Text from '../Text';
import { CommonStatus } from '../CommonStatus';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: APIRedemptionI[];
};

const APIRedemptionsTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'redemption code',
      width: 240,
      ellipsis: true,
      dataIndex: 'code',
      render: (code: string) => {
        const maskedKey = displayStringWithStar(code);
        return (
          <>
            <span className="tw-mr-2 tw-min-w-[180px] tw-inline-block">{maskedKey}</span>
            <CopyToClipboard text={code ?? ''} onCopy={() => message.info('Copied')}>
              <Button className="tw-border-none tw-p-0">
                <Icon name="icon-file_copy" size={20} className="tw-text-primary-500" />
              </Button>
            </CopyToClipboard>
          </>
        );
      },
    },
    {
      title: 'validity date',
      width: 200,
      ellipsis: true,
      render: (record: APIRedemptionI) => {
        if (record.status !== 'redeemed') return '';
        const validityDate = dayjs(record.createdAt).add(record.duration, 'month');
        return (
          <span>
            {DateFormat(record.createdAt)} - {DateFormat(validityDate.toDate())}
          </span>
        );
      },
    },
    {
      title: 'new api calls',
      dataIndex: 'newLimit',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'created by',
      width: 200,
      dataIndex: 'createdBy',
      ellipsis: true,
      render: (createdBy: { email: string; id: number; username: string }) => {
        return (
          <span className="tw-flex tw-items-center tw-gap-2">
            {' '}
            <Avatar id={createdBy.id} className="!tw-w-6 !tw-h-6 tw-rounded-[50%]" />
            <Text variant="buttonSm">{createdBy.username}</Text>{' '}
          </span>
        );
      },
    },
    {
      title: 'expiry date',
      dataIndex: 'expiryDate',
      width: 150,
      ellipsis: true,
      render: (expiryDate: string) => {
        return <span>{DateFormat(expiryDate)}</span>;
      },
    },
    {
      title: 'code status',
      dataIndex: 'status',
      width: 150,
      ellipsis: true,
      render: (status: string) => {
        return <CommonStatus status={status} />;
      },
    },
    {
      title: 'redeemed By',
      width: 150,
      ellipsis: true,
      dataIndex: 'redeemedBy',
      render: (redeemedBy: { id: number; displayName: string }) => {
        return redeemedBy?.displayName ? <Text variant="bodyMd">{redeemedBy?.displayName}</Text> : '';
      },
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default APIRedemptionsTable;
