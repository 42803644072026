import { Button, Checkbox, Form, Input, message } from 'antd';
import Text from '../../Text';
import React, { useEffect, useState } from 'react';
import PrimaryButton from '../../buttons/PrimaryButton';
import CheckCircleIcon from '../../../svgs/CheckCircleIcon';
import { Notice } from '../../Notice';
import CopyToClipboard from 'react-copy-to-clipboard';
import Icon from '../../Icon';
import { GenerateAPIKeyResponseI } from '../../../interfaces/apiManagement';

type Props = {
  data?: GenerateAPIKeyResponseI;
  setCurrentTab?: (_: string) => void;
  setIsGenerateKey: (_: boolean) => void;
};

const GenerateAPIKeysForm: React.FC<Props> = ({ data, setCurrentTab, setIsGenerateKey }) => {
  const [form] = Form.useForm();
  const [isChecked, setIsChecked] = useState(false);

  const handleOnFinish = () => {
    setCurrentTab?.('2');
    setIsGenerateKey(false);
  };

  const onVerifyCheckbox = (event: any) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    form.setFieldsValue({
      publicKey: data?.publicKey,
      secretKey: data?.secretKey,
    });
  }, [data]);

  return (
    <>
      <div className="tw-flex tw-items-center tw-flex-col">
        <CheckCircleIcon width={48} height={48} color="#0A7A08" />
        <Text variant="h3" className="tw-mb-8 tw-flex tw-flex-col tw-justify-center tw-items-center tw-mt-2">
          <span>You have successfully generated API Key:</span>
          <span>{data?.name}</span>
        </Text>
      </div>
      <Notice
        type="warning"
        description={
          <span>
            Ensure that you copy and store the <b>API Secret Key</b> somewhere secure.
            {` `}
            <b>You will not be able to view it again after you close this modal</b>
          </span>
        }
        closable={false}
      />
      <Form form={form} scrollToFirstError autoComplete="off" layout="vertical">
        <div className="form-container">
          <div className="form-content">
            <Form.Item label="Public Key" name="publicKey">
              <Input
                placeholder="Type API Key Name"
                disabled
                suffix={
                  <CopyToClipboard text={data?.publicKey ?? ''} onCopy={() => message.info('Copied public key!')}>
                    <Button className="tw-border-none tw-p-0">
                      <Icon name="icon-file_copy" size={20} className="tw-text-primary-500" />
                    </Button>
                  </CopyToClipboard>
                }
              />
            </Form.Item>
            <Form.Item label="Secret Key" name="secretKey">
              <Input
                placeholder="Type API Key Name"
                disabled
                suffix={
                  <CopyToClipboard text={data?.secretKey ?? ''} onCopy={() => message.info('Copied secret key!')}>
                    <Button className="tw-border-none tw-p-0">
                      <Icon name="icon-file_copy" size={20} className="tw-text-primary-500" />
                    </Button>
                  </CopyToClipboard>
                }
              />
            </Form.Item>
            <Form.Item
              name="isChecked"
              className="tw-py-2"
              rules={[
                { required: true, message: '' },
                {
                  validator: () => {
                    if (!isChecked) {
                      message.error('Please fill in all required checkboxes ');
                      return Promise.reject();
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Checkbox onChange={onVerifyCheckbox}>
                <p>Confirm that I have copied and stored my Secret Key</p>
              </Checkbox>
            </Form.Item>
            <PrimaryButton
              label="Done"
              htmlType="submit"
              className="tw-w-full"
              disabled={!isChecked}
              onClick={handleOnFinish}
            />
          </div>
        </div>
      </Form>
    </>
  );
};

export default GenerateAPIKeysForm;
