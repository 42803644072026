import { Form, Input, Modal } from 'antd';
import React, { useState } from 'react';
import Text from '../Text';
import SecondaryButton from '../buttons/SecondaryButton';
import Icon from '../Icon';
import PlatformIssueReportingModal from '../PlatformIssueReportingModal';
import { CurrentUserI } from '../../interfaces/auth';
import { useOutletContext } from 'react-router-dom';
import PrimaryButton from '../buttons/PrimaryButton';
import { getApiRedeemptionCode } from '../../apis/apiManagement';
import ErrorModalIcon from '../../svgs/ErrorModalIcon';
import { APIManagementRedemptionCodeI } from '../../interfaces/apiManagement';
import ApiRedeemptionSuccessModal from './RedeemptionSuccessModal';

type Props = {
  isModalVisible: boolean;
  setIsModalVisible: (_: boolean) => void;
};
const modal = Modal;
const ApiUsageIncreaseUsageModal: React.FC<Props> = ({ isModalVisible, setIsModalVisible }) => {
  const currentUser: CurrentUserI = useOutletContext();
  const [form] = Form.useForm();
  const [isContactUsModalOpen, setIsContactUsModalOpen] = useState<boolean>(false);
  const [isCodeError, setIsCodeError] = useState<string>('');
  const [successData, setSuccessData] = useState<APIManagementRedemptionCodeI>();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onContactUsCancel = () => {
    setIsContactUsModalOpen(false);
    setIsModalVisible(true);
  };

  const onContactUsClick = () => {
    setIsModalVisible(false);
    setIsContactUsModalOpen(true);
  };

  const handleOnFinish = async (values: any) => {
    try {
      const response = await getApiRedeemptionCode(values.code);
      setSuccessData(response);
      setIsCodeError('');
      setIsSuccessModalOpen(true);
      form.resetFields();
    } catch (error) {
      setIsCodeError('Invalid code');
      const errorModal = modal.error({
        type: 'error',
        title: 'Redemption Code is Invalid!',
        content: 'Please check and try again, or contact us for support.',
        className: 'context-modal-custom-container',
        closable: true,
        footer: null,
        icon: <ErrorModalIcon color="#B13939" />,
        width: 507,
      });

      setTimeout(() => {
        errorModal.destroy();
      }, 3000);
    }
  };

  return (
    <>
      <Modal open={isModalVisible} onOk={handleCancel} onCancel={handleCancel} footer={null} width={469}>
        <Text variant="h2">Increase API Usage</Text>
        <Text variant="bodyLg" className="tw-mt-6">
          In order to increase your partnership’s current API usage, please send your{' '}
          <b>requested number of Monthly Usage increment</b> via Contact Us form.
        </Text>
        <SecondaryButton
          label="Contact Us"
          icon={<Icon name="icon-feedback" size={20} />}
          onClick={onContactUsClick}
          className="tw-mt-2 !tw-border-grey-300 tw-text-grey-900"
        />
        <Text variant="bodyLg" className="tw-my-6">
          Once we have reviewed your request, we will send you a Redemption Code via your email, which you can enter and
          redeem your requested Monthly Usage increment:
        </Text>
        <Form
          layout="vertical"
          form={form}
          onFinish={handleOnFinish}
          scrollToFirstError
          autoComplete="off"
          requiredMark
        >
          <div className="form-container">
            <div className="form-content !tw-mt-0 tw-mb-16">
              <Form.Item
                name="code"
                rules={[{ required: true, message: 'This field is required' }]}
                help={isCodeError ?? ''}
                validateStatus={isCodeError ? 'error' : undefined}
              >
                <Input placeholder="Enter code" />
              </Form.Item>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-3">
              <PrimaryButton label="Redeem" htmlType="submit" className="tw-w-full" />
              <SecondaryButton label="Cancel" onClick={handleCancel} className="tw-w-full" />
            </div>
          </div>
        </Form>
      </Modal>
      <PlatformIssueReportingModal
        open={isContactUsModalOpen}
        setOpen={setIsContactUsModalOpen}
        currentUser={currentUser}
        categoryDefaultValue="increaseAPIUsageLimit"
        handleCancel={onContactUsCancel}
      />
      <ApiRedeemptionSuccessModal
        isModalVisible={isSuccessModalOpen}
        setIsModalVisible={setIsSuccessModalOpen}
        data={successData}
        setIncreaseApiUsageOpen={setIsModalVisible}
      />
    </>
  );
};

export default ApiUsageIncreaseUsageModal;
