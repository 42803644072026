import { BaseResponse } from '../interfaces';
import {
  APIKeyHistoryCollectionI,
  APIKeyHistorySearchParamsI,
  APIKeysCollectionI,
  APIKeyUsageI,
  APIManagementRedemptionCodeI,
  APIManagementSummaryI,
  APIUsageHistorySearchParamsI,
  GenerateAPIKeyResponseI,
} from '../interfaces/apiManagement';
import { handleFilterParams } from '../utils/global';
import { api } from './api';

export const getApiManagementSummary = async () => {
  const { data: response } = await api.get<BaseResponse<APIManagementSummaryI>>(`api-managements/summary`);
  return response?.data;
};

export const getAPIKeyMonthlyUsage = async (params?: APIUsageHistorySearchParamsI) => {
  const { data: response } = await api.get<{ data: APIKeyUsageI }>(
    `api-managements/usage?${handleFilterParams(params)}`,
  );
  return response?.data;
};

export const getApiManagementKeys = async () => {
  const { data: response } = await api.get<BaseResponse<APIKeysCollectionI>>(`api-managements/keys`);
  return response?.data;
};

export const getAPIKeyHistoriesList = async (params?: APIKeyHistorySearchParamsI) => {
  const { data: response } = await api.get<BaseResponse<APIKeyHistoryCollectionI>>(
    `api-managements/key-histories?${handleFilterParams(params)}`,
  );
  return response?.data;
};

export const generateApiKey = async (values: { name: string }) => {
  const { data: response }: { data: { data: GenerateAPIKeyResponseI } } = await api.post(
    'api-managements/generate-key',
    values,
  );
  return response.data;
};

export const deletePublicKey = async (publicKey: string) => {
  const { data: response }: any = await api.patch(`api-managements/keys/status`, { publicKey });
  return response.data;
};

export const getApiRedeemptionCode = async (code: string) => {
  const { data: response } = await api.get<{ data: APIManagementRedemptionCodeI }>(
    `api-managements/redemption-code/${code}`,
  );
  return response?.data;
};
