import React from 'react';
import TableBase, { Props as TableProps } from './Base';
import { DateTimeFormat, displayStringWithStar } from '../../utils/global';
import { UserI } from '../../interfaces/user';
import Avatar from '../User/Avatar';
import CopyToClipboard from 'react-copy-to-clipboard';
import Icon from '../Icon';
import { Button, message } from 'antd';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: any[];
};

const APIKeysTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'KEY NAME',
      width: 150,
      dataIndex: 'name',
      ellipsis: true,
    },
    {
      title: 'Public Key',
      width: 360,
      dataIndex: 'publicKey',
      ellipsis: true,
      render: (publicKey: string) => {
        const maskedKey = displayStringWithStar(publicKey);
        return (
          <>
            <span className="tw-mr-2 tw-min-w-[260px] tw-inline-block">{maskedKey}</span>
            <CopyToClipboard text={publicKey ?? ''} onCopy={() => message.info('Copied')}>
              <Button className="tw-border-none tw-p-0">
                <Icon name="icon-file_copy" size={20} className="tw-text-primary-500" />
              </Button>
            </CopyToClipboard>
          </>
        );
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      width: 280,
      ellipsis: true,
      render: (createdAt: string) => {
        return DateTimeFormat(createdAt);
      },
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      width: 200,
      ellipsis: true,
      render: (user: UserI) => {
        return (
          <div>
            <Avatar id={user?.id ?? 0} className="!tw-w-10 !tw-h-10 tw-rounded-[50%]" /> {user.username}
          </div>
        );
      },
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default APIKeysTable;
