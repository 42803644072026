import React, { useMemo, useState } from 'react';
import Text from '../Text';
import Icon from '../Icon';
import TooltipIcon from '../buttons/TooltipIcon';
import APIKeysTable from '../tables/APIKeys';
import { Dropdown, message, Spin, Tabs, TabsProps } from 'antd';
import DangerButton from '../buttons/DangerButton';
import { useMutation, useQueryClient } from 'react-query';
import { deletePublicKey } from '../../apis/apiManagement';
import { APIKeyI, APIKeysCollectionI } from '../../interfaces/apiManagement';
import APIKeysHistory from './History';
import { Notice } from '../Notice';
import SizeBox from '../SizeBox';
import EmptyData from '../EmptyData';
import GenerateAPIKey from './GenerateKey';

const apiKeyTooltip =
  'Partner is granted maximum of 5 API keys. Once you reach your quota limit, you will have to delete an existing API Key to create a new key';

type Props = {
  isFetching: boolean;
  apiKeysList?: APIKeysCollectionI;
};

const APIKeysTab: React.FC<Props> = ({ isFetching, apiKeysList }) => {
  const queryClient = useQueryClient();
  const [isGenerateModalOpen, setIsGenerateModalOpen] = useState<boolean>(false);

  const data = useMemo(() => {
    if (apiKeysList) {
      return {
        list: apiKeysList.apiKeys ?? [],
        total: apiKeysList.apiKeys.length ?? 0,
      };
    }
  }, [apiKeysList]);

  const { mutateAsync: updateMutate, isLoading: isPageLoading } = useMutation(deletePublicKey, {
    onSuccess: () => {
      queryClient.invalidateQueries();
      message.success('API Key Deleted!');
    },
  });

  const handleDelete = (publicKey: string) => {
    updateMutate(publicKey);
  };

  const buttonFunction = () => {
    setIsGenerateModalOpen(true);
  };

  const withAction = (record: APIKeyI) => {
    return (
      <span className="action" style={{ display: 'flex' }}>
        <Dropdown.Button
          overlayClassName="table-action-menu"
          menu={{
            items: [
              {
                key: '1',
                label: (
                  <DangerButton
                    className="btn-table-action-danger"
                    label="Delete"
                    onOk={() => handleDelete(record.publicKey)}
                    okText="Confirm Delete"
                    layout="horizontal"
                    modalType="danger"
                    content={
                      <>
                        <Text variant="h3">Delete {record.name}?</Text>
                        <Text variant="bodyLg">
                          Any prior connection to this API Key will cease to function. You will need to generate and
                          reconnect a new API Key for any existing connections.
                        </Text>
                      </>
                    }
                  />
                ),
              },
            ],
          }}
        ></Dropdown.Button>
      </span>
    );
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <Text variant="labelLg" className="tw-flex tw-items-center tw-gap-2">
          <Icon name="icon-history" size={20} /> <span>History</span>
        </Text>
      ),
      children: <APIKeysHistory />,
    },
  ];

  return (
    <Spin spinning={isFetching || isPageLoading}>
      <div className="tw-bg-white tw-border-primary tw-rounded-[10px] tw-p-6 tw-gap-3 tw-flex-col tw-flex">
        <span className="tw-flex tw-items-center tw-gap-3">
          <Text variant="h3">API Keys</Text>
          <TooltipIcon buttonClassName="tw-w-5 tw-h-5" text={apiKeyTooltip} />
        </span>
        <Text variant="h1">
          <span style={{ color: (data?.total ?? 0) < 5 ? '#191919' : '#B13939' }}>{data?.total}</span> / 5
        </Text>
        <div className="tw-relative tw-w-full tw-h-4 tw-bg-grey-400 tw-rounded-full tw-mb-3">
          <span
            className="tw-absolute tw-left-0 tw-top-0 tw-rounded-full tw-h-full"
            style={{
              width: data?.total !== 0 ? `${((data?.total ?? 1) / 5) * 100}%` : '0%',
              background: (data?.total ?? 0) < 5 ? '#0a7a08' : '#B13939',
            }}
          />
        </div>
        {data?.total === 5 && (
          <>
            <Notice
              type="warning"
              message="Maximum 5 API keys generated reached!"
              description="You will have to delete an existing API Key to create a new key."
              closable={false}
            />
            <SizeBox height={24} />
          </>
        )}
        {data?.total === 0 && (
          <>
            <EmptyData
              title="No API Key Generated"
              button={{ title: 'Generate Key', type: 'primary', buttonFunction, icon: <Icon name="icon-add" /> }}
            />
          </>
        )}
        {data && data?.total > 0 && (
          <APIKeysTable rowKey="publicKey" loading={isFetching} dataSource={data?.list} withAction={withAction} />
        )}
      </div>
      <div className="tw-bg-white tw-border-primary tw-rounded-[10px] tw-p-6 tw-gap-3 tw-flex-col tw-flex tw-mt-6">
        <Tabs defaultActiveKey="1" items={items} rootClassName="hardware-attachment-tabs" />
      </div>
      <GenerateAPIKey isModalVisible={isGenerateModalOpen} setIsModalVisible={setIsGenerateModalOpen} />
    </Spin>
  );
};

export default APIKeysTab;
