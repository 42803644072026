import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PAGE_SIZE } from '../../constants';
import { useQuery } from 'react-query';
import { handleFilterParams } from '../../utils/global';
import { Pagination, Spin } from 'antd';
import SizeBox from '../SizeBox';
import DisplayLastUpdateApp from '../DisplayLastUpdate';
import APIKeyHistoryItem from './HistoryItem';
import { APIKeyHistoryI, APIKeyHistorySearchParamsI } from '../../interfaces/apiManagement';
import { getAPIKeyHistoriesList } from '../../apis/apiManagement';
import APIKeyHistoryFilter from './APIKeyHistoryFilter';
import EmptyData from '../EmptyData';

const APIKeysHistory: React.FC = () => {
  const navigate = useNavigate();
  const urlSearch = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const queryParams = useMemo(() => {
    const page = urlSearch.get('page') ?? 1;
    const limit = urlSearch.get('limit') ?? PAGE_SIZE;
    const orderType = urlSearch.get('orderType') ?? 'desc';
    const orderBy = urlSearch.get('orderBy') ?? 'createdAt';
    const search = urlSearch.get('search');

    return {
      page,
      limit,
      orderType,
      orderBy,
      search: search,
    } as APIKeyHistorySearchParamsI;
  }, [urlSearch]);

  const [isRefetchLoading, setIsRefetchLoading] = useState<boolean>(false);
  const {
    data: { histories = [], total = 0 } = {},
    isLoading,
    refetch: refetchData,
  } = useQuery(['api-key-histories', `list`, queryParams], () => getAPIKeyHistoriesList(queryParams), {
    select({ histories, total }) {
      return { histories, total };
    },
  });

  const currentPage = queryParams?.page ?? 1;

  const setQueryParams = (params: Partial<APIKeyHistorySearchParamsI>) => {
    const query = handleFilterParams(params);
    navigate(`?${query}`);
  };

  const handleFilterChange = (values: APIKeyHistorySearchParamsI) => {
    setQueryParams({ ...queryParams, ...values });
  };

  const handleFilter = (params: { page: number; per_page: number }) => {
    setQueryParams({ ...queryParams, page: params.page, limit: params.per_page });
  };

  return (
    <div className="partner-history-container">
      <Spin spinning={isRefetchLoading ?? isLoading} className="partner-history-container">
        <APIKeyHistoryFilter
          queryParams={queryParams}
          onChange={handleFilterChange}
          refetchData={refetchData}
          setIsRefetchLoading={setIsRefetchLoading}
        />
        <SizeBox height={16} />
        <DisplayLastUpdateApp />
        <SizeBox height={8} />
        {histories.length === 0 && !isLoading && (
          <>
            <EmptyData title="No API Event" />
          </>
        )}
        {histories.map((item: APIKeyHistoryI) => {
          return <APIKeyHistoryItem item={item} key={item?.uuid} />;
        })}
        <div className="pagination-container tw-mt-[44px]">
          <Pagination
            total={total}
            pageSize={queryParams.limit ?? PAGE_SIZE}
            showTotal={(total) => `${total} Items`}
            current={currentPage}
            onChange={(page, pageSize) => handleFilter({ page, per_page: pageSize })}
            showSizeChanger
          />
        </div>
      </Spin>
    </div>
  );
};

export default APIKeysHistory;
