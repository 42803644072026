import { Form, Input, MenuProps } from 'antd';
import React from 'react';
import { renderSortList } from '../../utils/sort';
import DropdownApp from '../Dropdown';
import Icon from '../Icon';
import { APIKeyHistorySearchParamsI } from '../../interfaces/apiManagement';
import SearchInputIcon from '../../svgs/SearchInputIcon';
import RefreshButton from '../buttons/RefreshButton';

type Props = {
  queryParams: APIKeyHistorySearchParamsI & { viewMode?: string };
  onChange: (params: APIKeyHistorySearchParamsI) => void;
  refetchData: () => void;
  setIsRefetchLoading: (isLoading: boolean) => void;
};

const APIKeyHistoryFilter: React.FC<Props> = ({ queryParams, onChange, refetchData, setIsRefetchLoading }) => {
  const [form] = Form.useForm();

  const handleOnChange = (params: Partial<APIKeyHistorySearchParamsI & { viewMode?: string }>) => {
    onChange({ ...queryParams, ...params });
  };

  const handleDropdownItemClick: MenuProps['onClick'] = (e: any) => {
    const { key } = e;
    const [orderBy, type] = key.split('-');
    handleOnChange({
      orderBy: orderBy as APIKeyHistorySearchParamsI['orderBy'],
      orderType: type as APIKeyHistorySearchParamsI['orderType'],
    });
  };

  const items: MenuProps['items'] = renderSortList(queryParams);

  const menuDropdownProps = {
    items,
    onClick: handleDropdownItemClick,
  };

  return (
    <div className="filter-form-container">
      <div className="form-wrap">
        <Form
          initialValues={{ search: queryParams.search }}
          form={form}
          requiredMark={false}
          colon={false}
          onFinish={({ search }) => handleOnChange({ search })}
          scrollToFirstError
          className="tw-w-full"
        >
          <div className="form-container">
            <div className="form-content tw-flex-auto">
              <Form.Item name="search" className="search-box tw-w-full">
                <Input placeholder="Search" prefix={<SearchInputIcon />} allowClear />
              </Form.Item>
            </div>
            <div className="tw-flex tw-gap-3 xs:tw-gap-1 xs:tw-w-full xs:tw-justify-end">
              <DropdownApp
                icon={<Icon name="icon-sort" size={22} />}
                menu={menuDropdownProps}
                size="small"
                placement="bottomRight"
                type="secondary"
              />
              <RefreshButton doRefetch={refetchData} setIsLoading={setIsRefetchLoading} />
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default APIKeyHistoryFilter;
