import React, { useState } from 'react';
import Text from '../../components/Text';
import { Link, useOutletContext } from 'react-router-dom';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import PlusIcon from '../../svgs/PlusIcon';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import APISummaryTab from '../../components/APIManagement/SummaryTab';
import APIKeysTab from '../../components/APIManagement/APIKeysTab';
import GenerateAPIKey from '../../components/APIManagement/GenerateKey';
import APIUsageTab from '../../components/APIManagement/APIUsageTab';
import { handleAccessControl } from '../../utils/role';
import { CurrentUserI } from '../../interfaces/auth';
import { useQuery } from 'react-query';
import { getApiManagementKeys } from '../../apis/apiManagement';

const APIManagement: React.FC = () => {
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'api-managements');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentTab, setCurrentTab] = useState('1');
  const { data: apiKeysList, isFetching } = useQuery(['api-management-keys', 'list', {}], () => getApiManagementKeys());

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: 'Summary',
      children: <APISummaryTab setCurrentTab={setCurrentTab} />,
    },
    {
      key: '2',
      label: 'API Keys',
      children: <APIKeysTab isFetching={isFetching} apiKeysList={apiKeysList} />,
    },
    {
      key: '3',
      label: 'API Usage',
      children: <APIUsageTab />,
    },
  ];

  const handleTabsChange = (key: string) => {
    setCurrentTab(key);
  };

  return (
    <>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-p-4 tw-bg-grey-50">
        <div className="tw-flex-row tw-flex tw-items-center tw-justify-between tw-gap-4 xs:tw-flex-col">
          <div className="tw-flex tw-flex-col tw-gap-3">
            <Text component="h3" variant="h3">
              API Management
            </Text>
            <Text variant="bodyMd" className="tw-text-grey-700">
              Generate and manage API Keys to interact with the CircularONE API{' '}
              <Link
                to="https://production-api.circularone.ai/docs/"
                target="_blank"
                className="tw-font-bold tw-text-info-500"
              >
                Learn More
              </Link>
            </Text>
          </div>
          {access.isCanCreate && (
            <div className="page-header-actions">
              <PrimaryButton
                label="Generate Key"
                icon={<PlusIcon color={apiKeysList?.apiKeys.length === 5 ? '#898989' : '#FFFFFF'} />}
                onClick={() => setIsModalVisible(true)}
                disabled={apiKeysList?.apiKeys.length === 5}
              />
            </div>
          )}
        </div>
        <div>
          <Tabs activeKey={currentTab} items={tabItems} onChange={handleTabsChange} />
        </div>
      </div>
      <GenerateAPIKey
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        setCurrentTab={setCurrentTab}
      />
    </>
  );
};

export default APIManagement;
