import React, { useMemo } from 'react';
import Text from '../Text';
import { HardwarePerformanceT } from '../../interfaces/dashboard';
import { Image, Spin } from 'antd';
import HardwareImg from '../../images/Hardware.png';
import TooltipIcon from '../buttons/TooltipIcon';
import HorizonBarItem from './HorizonBarItem';
import Icon from '../Icon';
import useWindowResize from '../../hooks/useWindowResize';

type Props = {
  data?: HardwarePerformanceT['hardwareFleet'];
  isLoading: boolean;
  totalHardwareTooltip: string;
  hardwareTypeTooltip: string;
};

type HardwareTypeT = 'tote45' | 'tote175' | 'smartPour' | 'ecoPlant';

const HardwareFleet: React.FC<Props> = ({ data, isLoading, totalHardwareTooltip, hardwareTypeTooltip }) => {
  const { width } = useWindowResize();
  const mappedData = useMemo(() => {
    const sortedData = Object.entries(data ?? {})
      .filter(([key]) => key !== 'totalHardware')
      .sort(([, a], [, b]) => b - a);

    return sortedData.map(([key, value]) => ({
      type: key as HardwareTypeT,
      count: value,
    })) as { type: HardwareTypeT; count: number }[];
  }, [data]);

  return (
    <Spin spinning={isLoading}>
      <Text
        component={width < 767 ? 'h3' : 'h2'}
        variant={width < 767 ? 'h3' : 'h2'}
        className="tw-mb-4 tw-flex tw-items-center tw-text-grey-800"
      >
        Current Hardware Fleet
      </Text>
      <Text variant={width < 767 ? 'h4' : 'h3'} className="tw-text-grey-700">
        Overview
      </Text>
      <div className="tw-flex tw-items-start tw-gap-6 tw-mt-2 tw-mb-3">
        <div className="tw-flex-1 tw-flex tw-gap-3 tw-rounded-lg tw-border tw-border-solid tw-border-grey-300 tw-p-3">
          <Image src={HardwareImg} preview={false} />
          <div className="tw-flex tw-flex-col">
            <Text variant="h4" className="tw-text-grey-600 tw-flex tw-gap-1 tw-items-center">
              Total Hardware <TooltipIcon text={totalHardwareTooltip} />
            </Text>
            <Text variant="h1">{data?.totalHardware}</Text>
          </div>
        </div>
      </div>
      <div className="tw-bg-grey-50 tw-border tw-border-solid tw-border-grey-300 tw-p-6 tw-rounded-lg sm:tw-p-3">
        <span className="tw-flex tw-items-center tw-mb-5 tw-gap-2">
          <Text variant="inputLabelMd" className="tw-text-grey-700 tw-flex tw-gap-1 tw-items-center">
            Hardware Types <TooltipIcon text={hardwareTypeTooltip} />
          </Text>
        </span>
        <div className="tw-h-[150px] tw-overflow-auto tw-pr-3">
          {mappedData.map((item: { type: HardwareTypeT; count: number }) => {
            if (item.count === 0) return null;
            return (
              <HorizonBarItem
                key={item.type}
                title={
                  item.type === 'tote45'
                    ? 'ecoTOTE 4.50L'
                    : item.type === 'tote175'
                      ? 'ecoTOTE 1.75L'
                      : item.type === 'smartPour'
                        ? 'SmartPour'
                        : item.type === 'ecoPlant'
                          ? 'ecoPLANT'
                          : ''
                }
                icon={
                  item.type === 'tote45' ? (
                    <Icon name="icon-eco-tote" size={20} />
                  ) : item.type === 'tote175' ? (
                    <Icon name="icon-eco-tote" size={20} />
                  ) : item.type === 'smartPour' ? (
                    <Icon name="icon-smart-pour" size={20} />
                  ) : item.type === 'ecoPlant' ? (
                    <Icon name="icon-eco-plant" size={20} />
                  ) : null
                }
                valueText={data?.[item.type]}
                itemTotalValue={((data?.[item.type] ?? 0) / (data?.totalHardware ?? 0)) * 100}
                maxValue={(data?.totalHardware ?? 0) * 100}
                itemValue={((data?.[item.type] ?? 0) / (data?.totalHardware ?? 0)) * 100}
                isBackground={false}
                labelGap={138}
              />
            );
          })}
        </div>
      </div>
    </Spin>
  );
};

export default HardwareFleet;
