import React from 'react';
import Text from '../Text';

const ApiUsageHistoryFilterContent: React.FC = () => {
  return (
    <div className="tw-min-w-[385px]">
      <Text variant="h3">Filter</Text>
    </div>
  );
};

export default ApiUsageHistoryFilterContent;
