import React, { Fragment } from 'react';
import './RoleCollapseDetail.scss';
import { Collapse, CollapseProps, Tooltip } from 'antd';
import ActionSection from './ActionSection';
import ActivateIcon from '../../svgs/role/ActivateIcon';
import CreateIcon from '../../svgs/role/CreateIcon';
import EditIcon from '../../svgs/role/EditIcon';
import UpdateStatusIcon from '../../svgs/role/UpdateStatusIcon';
import { ModuleI } from '../../interfaces/module';
import { RoleI } from '../../interfaces/role';
import RoleDashboardCollapseDetail from './RoleDashboardCollapseDetail';
import RolePartnerCollapseDetail from './RolePartnerCollapseDetail';
import RoleHardwareCollapseDetail from './RoleHardwareCollapseDetail';
import RoleProductCollapseDetail from './RoleProductCollapseDetail';
import RolePlatformCollapseDetail from './RolePlatformCollapseDetail';
import RoleGeneralCollapseDetail from './GeneralCollapseDetail';
import { CurrentUserI } from '../../interfaces/auth';
import { useOutletContext } from 'react-router-dom';
import { handleAccessControl } from '../../utils/role';
import RoleNotificationCollapseDetail from './RoleNotificationCollapseDetail';
import RoleIntegrationCollapseDetail from './RoleIntegrationCollapseDetail';

type Props = {
  modules?: ModuleI[];
  role?: RoleI;
};

const RoleCollapseDetail: React.FC<Props> = ({ modules, role }) => {
  const currentUser: CurrentUserI = useOutletContext();
  const access = handleAccessControl(currentUser, 'roles');

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'DASHBOARD MANAGEMENT',
      children: <RoleDashboardCollapseDetail modules={modules} role={role} />,
    },
    ...(access.isCanReadPlatform
      ? [
          {
            key: '2',
            label: 'PLATFORM MANAGEMENT',
            children: <RolePlatformCollapseDetail modules={modules} role={role} />,
          },
        ]
      : []),
    {
      key: '3',
      label: 'PARTNER MANAGEMENT',
      children: <RolePartnerCollapseDetail modules={modules} role={role} />,
    },
    {
      key: '4',
      label: 'HARDWARE MANAGEMENT',
      children: <RoleHardwareCollapseDetail modules={modules} role={role} />,
    },
    {
      key: '5',
      label: 'PRODUCT MANAGEMENT',
      children: <RoleProductCollapseDetail modules={modules} role={role} />,
    },
    {
      key: '6',
      label: 'NOTIFICATION MANAGEMENT',
      children: <RoleNotificationCollapseDetail modules={modules} role={role} />,
    },
    {
      key: '7',
      label: 'INTEGRATION MANAGEMENT',
      children: <RoleIntegrationCollapseDetail modules={modules} role={role} />,
    },
  ];

  const generalItems: CollapseProps['items'] = [
    {
      key: '1',
      label: 'GENERAL',
      children: <RoleGeneralCollapseDetail role={role} />,
    },
  ];

  return (
    <div className="role-collapse-detail-container">
      <div>
        <Collapse items={generalItems} defaultActiveKey={['1']} expandIconPosition="end" />
      </div>
      <ActionSection
        label={<span className="grey-color">Action</span>}
        content={
          <Fragment>
            <Tooltip title="View" className="action-item">
              <ActivateIcon />
              <span className="tooltip-text">View</span>
            </Tooltip>
            <Tooltip title="Create" className="action-item">
              <CreateIcon />
              <span className="tooltip-text">Create</span>
            </Tooltip>
            <Tooltip title="Edit" className="action-item">
              <EditIcon />
              <span className="tooltip-text">Edit</span>
            </Tooltip>
            <Tooltip title="Update Status" className="action-item">
              <UpdateStatusIcon />
              <span className="tooltip-text">Update Status</span>
            </Tooltip>
          </Fragment>
        }
      />
      <Collapse items={items} defaultActiveKey={['1', '2', '3', '4', '5', '6', '7']} expandIconPosition="end" />
    </div>
  );
};

export default RoleCollapseDetail;
