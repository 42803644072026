import { CheckboxOptionI, UploadAttachmentCategoryI } from '../interfaces';

export const APP_NAME = 'Ecospirits';
export const APP_VERSION = '1.4.0';
export const TOKEN_NAME = '_ecospirits_session';
export const PAGE_SIZE = 20;
export const DATETIME_FORMAT_FRONTEND = 'h:mm A, MMM Do, YYYY';
export const DATE_FORMAT_BACKEND = 'YYYY/MM/DD';
export const DATE_FORMAT_QUERY = 'YYYY-MM-DD';
export const DATETIME_FORMAT_QUERY = 'YYYY-MM-DD HH:mm:ss';
export const DATETIME_FORMAT = 'DD MMM YYYY h:mm A (UTC Z)';
export const DATE_FORMAT_FRONTEND = 'DD MMM YYYY';
export const DASHBOARD_DATE_FORMAT = 'DD MMM';
export const TIME_FORMAT_FRONTEND = 'h:mm A';
export const BottleCodeRegex = /^[a-zA-Z0-9]{6}$/;
export const DecimalRegex = /^(100(\.0)?|[0-9]?[0-9](\.\d)?)$/;
export const EightAlphanumericCharactersRegex = /^[a-zA-Z0-9]{8}$/;
export const TenAlphanumericCharactersRegex = /^[a-zA-Z0-9]{10}$/;
export const EmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const PASSWORD_TIP = '9 characters minimum, 1 uppercase, 1 lowercase, 1 numeric, 1 special character';

export const PASSWORD_REG_EXP = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.])(?=.{9,})';

export const UserTypes = [
  { name: 'EcoSpirits Admin', value: 'ecoSpiritsAdmin' },
  { name: 'Partner Admin', value: 'partnerAdmin' },
  { name: 'Partner', value: 'partner' },
];

export const UserRoles = [
  { name: 'EcoSpirits Admin', value: 'ecoSpiritsAdmin' },
  { name: 'Partner Admin', value: 'partnerAdmin' },
  { name: 'Partner', value: 'partner' },
];

export const Markets = [
  { name: 'AMER', value: 'AMER' },
  { name: 'APAC', value: 'APAC' },
  { name: 'EMEA', value: 'EMEA' },
];

export const AttachmentCategories = [
  { name: 'Certificate', value: 'certificate' },
  { name: 'Logo', value: 'logo' },
  { name: 'Others', value: 'others' },
] as { name: string; value: UploadAttachmentCategoryI }[];

export const FaultCategories = [
  { name: 'Certificate', value: 'certificate' },
  { name: 'Fault', value: 'fault' },
  { name: 'Others', value: 'others' },
] as { name: string; value: UploadAttachmentCategoryI }[];

export const ProductAttachmentCategories = [
  { name: 'Brand Plate', value: 'brandPlate' },
  { name: 'Certificate', value: 'certificate' },
  { name: 'Logo', value: 'logo' },
  { name: 'Others', value: 'others' },
] as { name: string; value: UploadAttachmentCategoryI }[];

export const SiteTypes = [
  { name: 'Consume', value: 'consume' },
  { name: 'Filling', value: 'filling' },
  { name: 'Storage', value: 'storage' },
];

export const SiteStatuses = [
  { name: 'Active', value: 'active' },
  { name: 'Inactive', value: 'inactive' },
  { name: 'Idle', value: 'idle' },
];

export const RoleStatuses = [
  { name: 'Active', value: 'active' },
  { name: 'Inactive', value: 'inactive' },
];

export const RoleCheckboxOptions = [
  { label: '', value: 'R' },
  { label: '', value: 'C' },
  { label: '', value: 'U' },
  { label: '', value: 'A' },
] as CheckboxOptionI[];

export const PageIgnoreCheckAuth = ['profile'] as string[];

export const ReasonForInactiveHardware = [
  { name: 'Lost', value: 'lost' },
  { name: 'Retired', value: 'retired' },
  { name: 'Others', value: 'others' },
];

export const PartnerStatusList = [
  { name: 'Active', value: 'active' },
  { name: 'Inactive', value: 'inactive' },
];

export const SiteStatusList = [
  { name: 'Active', value: 'active' },
  { name: 'Inactive', value: 'inactive' },
];

export const UserStatusList = [
  { name: 'Active', value: 'active' },
  { name: 'Inactive', value: 'nonActive' },
];

export const ProductStatusList = [
  { name: 'Active', value: 'active' },
  { name: 'Inactive', value: 'nonActive' },
];

export const RoleStatusList = [
  { name: 'Active', value: 'active' },
  { name: 'Inactive', value: 'inactive' },
];

export const EmailListStatusList = [
  { name: 'Active', value: 'active' },
  { name: 'Inactive', value: 'inactive' },
];

export const ContactUsReportCategories = [
  { name: 'General Inquiry', value: 'generalInquiry' },
  { name: 'Report an Issue', value: 'reportIssue' },
  { name: 'Feedback/Suggestion', value: 'feedbackSuggestion' },
  { name: 'Increase API Usage Limit', value: 'increaseAPIUsageLimit' },
];
