import React from 'react';
import { notification } from 'antd';
import axios from 'axios';
import { getRenewToken } from './utils/authentication';
import Cookies from 'js-cookie';
import { TOKEN_NAME } from './constants';
import ErrorIcon from './svgs/ErrorIcon';

let errorDisplayed = false;

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URI,
  timeout: 5000,
});

const customNotification = (description) => {
  if (!errorDisplayed) {
    notification.error({
      description,
      placement: 'bottomRight',
      icon: <ErrorIcon color='#B13939' />,
      className: 'AppNotification'
    });
    errorDisplayed = true;
    setTimeout(() => {
      errorDisplayed = false;
    }, 5000);
  }
};

axiosInstance?.interceptors.response.use(
  function (response) {
    errorDisplayed = false;
    return response;
  },
  async function (responseError) {
    console.log('error', responseError);
    const originalRequest = responseError.config;
    const { data } = responseError?.response || {};
    const error = data?.details || data?.detail || {};
    const status = responseError?.response?.status;
    if (data?.status === 401 && data?.detail && data?.detail?.includes('Permission denied!') && !originalRequest._retry) {
      originalRequest._retry = true;
      const newToken = await getRenewToken();
      originalRequest.headers.Authorization = `Bearer ${newToken}`;
      return axiosInstance(originalRequest);
    } else if (originalRequest._retry) {
      Cookies.remove(TOKEN_NAME);
      setTimeout(() => {
        window.location.href = '/login';
      }, 3000);
    }
      
    if (status === 520 || status === 413) {
      customNotification('Could not do this action!');
    }

    if ((status === 403 && data?.detail !== undefined) || (status === 404 && (data?.detail !== undefined || ( data?.detail === 'Uncaught Exception'))) || (status === 422 && data?.detail === 'Uncaught Exception')) {
      if (status === 404 && !originalRequest.url.includes('/hardwares/scan/') && !originalRequest.url.includes('/api-managements/redemtion-code')) {
        return window.location.href = '/not-allowed';
      }
    }

    const ignoreError = ['code exists', 'Forbidden!', 'Not found!', 'Hardware not found!'];

    if (data?.detail && !originalRequest._retry) {
      const message = data?.detail;
      if (!ignoreError.includes(message)) {
        customNotification(message);
      }
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
