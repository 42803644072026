import dayjs from 'dayjs';
export const getApiCallHistoryDateRange = () => {
  const currentYear = dayjs().year();
  const currentMonth = dayjs().month();
  const monthOptions = Array.from({ length: 12 }, (_, i) => {
    const month = (currentMonth - i + 12) % 12;
    const year = month > currentMonth ? currentYear - 1 : currentYear;
    const startOfMonth = dayjs().year(year).month(month).startOf('month');
    const endOfMonth = dayjs().year(year).month(month).endOf('month');
    const label =
      month === currentMonth
        ? `This Month (${startOfMonth.format('DD MMM YYYY')} - ${endOfMonth.format('DD MMM YYYY')})`
        : `${startOfMonth.format('DD MMM YYYY')} - ${endOfMonth.format('DD MMM YYYY')}`;
    return {
      label,
      value: startOfMonth.format('YYYY-MM'),
    };
  });

  return monthOptions;
};
