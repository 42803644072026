import { Button, Modal } from 'antd';
import React, { ReactNode } from 'react';
import './DangerButton.scss';
import { ExclamationCircleOutlined } from '@ant-design/icons';

type Props = {
  label?: ReactNode;
  content?: ReactNode;
  icon?: any;
  isDisplayIconConfirm?: boolean;
  htmlType?: 'button' | 'submit' | 'reset';
  loading?: boolean;
  style?: any;
  className?: string;
  okText?: string;
  cancelText?: string;
  layout?: 'vertical' | 'horizontal';
  modalType?: 'danger' | 'default';
  onCancel?: () => void;
  onOk?: () => void;
};

const DangerButton: React.FC<Props> = ({
  isDisplayIconConfirm,
  label,
  icon,
  htmlType,
  loading,
  style,
  content,
  className,
  okText = 'Yes',
  cancelText = 'Cancel',
  layout = 'vertical',
  modalType = 'default',
  onOk,
  onCancel,
}) => {
  const { confirm } = Modal;
  function showConfirm() {
    confirm({
      icon: isDisplayIconConfirm ? <ExclamationCircleOutlined /> : '',
      content: content ?? 'Do you really want to delete this one?',
      onOk() {
        onOk?.();
      },
      onCancel() {
        onCancel?.();
      },
      okText,
      cancelText,
      className: `danger-button-confirm-modal ${layout} ${modalType}`,
    });
  }
  return (
    <Button
      type="default"
      className={`btn-danger ${!label ? 'no-label' : ''} ${className} ${layout}`}
      htmlType={htmlType}
      loading={loading}
      style={style}
      onClick={showConfirm}
    >
      {icon}
      {label ?? label}
    </Button>
  );
};

export default DangerButton;
