import React from 'react';
import Avatar from '../User/Avatar';
import { DateTimeFormat } from '../../utils/global';
import Divider from '../Divider';
import SizeBox from '../SizeBox';
import Text from '../Text';
import { APIKeyHistoryI } from '../../interfaces/apiManagement';

type Props = {
  item: APIKeyHistoryI;
};

const APIKeyHistoryItem: React.FC<Props> = ({ item }) => {
  return (
    <div className="tw-p-4">
      <div className="tw-flex tw-justify-between tw-flex-wrap tw-mb-3 tw-items-center">
        <Text variant="labelMd" className="tw-text-grey-700">
          API
        </Text>
        <Text variant="bodyMd" className="tw-text-grey-800">
          {DateTimeFormat(item?.createdAt)}
        </Text>
      </div>
      <div className="tw-flex tw-items-center tw-gap-2 tw-mb-3 tw-flex-wrap">
        {item?.createdBy && (
          <>
            <Avatar
              id={item?.createdBy.id}
              className="!tw-w-[32px] !tw-h-[32px] !tw-max-w-[32px] !tw-max-h-[32px] tw-rounded-[100px]"
            />
            <Text variant="bodyMdB">{item?.createdBy.username}</Text>
          </>
        )}
        {item?.action === 'generated' && (
          <Text variant="bodyLg" className="tw-flex tw-flex-wrap tw-items-center">
            Generated an API {item.apiKey.name} &nbsp;
          </Text>
        )}
        {item?.action === 'deleted' && (
          <Text variant="bodyLg" className="tw-flex tw-flex-wrap tw-items-center">
            Deleted an API {item.apiKey.name} &nbsp;
          </Text>
        )}
      </div>
      <SizeBox height={16} />
      <Divider />
    </div>
  );
};

export default APIKeyHistoryItem;
