import { Badge, Dropdown, Form, MenuProps } from 'antd';
import React, { useEffect } from 'react';
import { renderSortList } from '../../utils/sort';
import DropdownApp from '../Dropdown';
import Icon from '../Icon';
import IconButton from '../buttons/IconButton';
import FilterIcon from '../../svgs/FilterIcon';
import { Select } from 'antd';
import dayjs from 'dayjs';
import { APIUsageHistorySearchParamsI } from '../../interfaces/apiManagement';
import ApiUsageHistoryFilterContent from './ApiUsageHistoryFilterContent';
import RefreshButton from '../buttons/RefreshButton';
import { getApiCallHistoryDateRange } from '../../utils/apiManagement';

type Props = {
  queryParams: APIUsageHistorySearchParamsI;
  refetchData: () => void;
  setIsRefetchLoading: (_: boolean) => void;
  setQueryParams: (_: Partial<APIUsageHistorySearchParamsI>) => void;
};

const APIMonthlyUsageHistoryFilter: React.FC<Props> = ({
  queryParams,
  refetchData,
  setIsRefetchLoading,
  setQueryParams,
}) => {
  const [form] = Form.useForm();
  const monthOptions = getApiCallHistoryDateRange();

  const handleOnChange = (params: APIUsageHistorySearchParamsI) => {
    console.log('params', params);
    setQueryParams({ ...queryParams, ...params });
  };

  const handleDropdownItemClick: MenuProps['onClick'] = (e: any) => {
    const { key } = e;
    const [orderBy, type] = key.split('-');
    handleOnChange({
      orderBy: orderBy as APIUsageHistorySearchParamsI['orderBy'],
      orderType: type as APIUsageHistorySearchParamsI['orderType'],
    });
  };

  const handleStartEndDate = (value: string) => {
    const [year, month] = value.split('-');
    const startOfMonth = dayjs()
      .year(Number(year))
      .month(Number(month) - 1)
      .startOf('month')
      .toISOString();
    const endOfMonth = dayjs()
      .year(Number(year))
      .month(Number(month) - 1)
      .endOf('month')
      .toISOString();
    handleOnChange({ startDate: startOfMonth, endDate: endOfMonth });
  };

  const onSelectMonthsChange = (value: string) => {
    handleStartEndDate(value);
  };

  const onFinish = (values: { search: string }) => {
    handleStartEndDate(values.search);
  };

  useEffect(() => {
    const { startDate, endDate } = queryParams || {};
    console.log('startDate', startDate);
    if (startDate && endDate) {
      form.setFieldsValue({ search: `${dayjs(startDate).format('YYYY-MM')}` });
    }
  }, [queryParams]);

  const items: MenuProps['items'] = renderSortList({});

  const menuDropdownProps = {
    items,
    onClick: handleDropdownItemClick,
  };

  return (
    <div className="filter-form-container">
      <div className="form-wrap">
        <Form
          initialValues={{ search: monthOptions[0] }}
          form={form}
          requiredMark={false}
          colon={false}
          onFinish={onFinish}
          scrollToFirstError
          className="tw-w-full"
        >
          <div className="form-container">
            <div className="form-content tw-flex-auto">
              <Form.Item name="search" className="tw-flex-1 [&>div>div>div>div>div]:tw-w-full sm:tw-w-full !tw-mb-0">
                <Select
                  options={monthOptions}
                  placeholder="Select Month"
                  className="!tw-mb-0"
                  onChange={onSelectMonthsChange}
                />
              </Form.Item>
            </div>
            <div className="tw-flex tw-gap-3 xs:tw-gap-1 xs:tw-w-full xs:tw-justify-end">
              <DropdownApp
                icon={<Icon name="icon-sort" size={22} />}
                menu={menuDropdownProps}
                size="small"
                placement="bottomRight"
                type="secondary"
              />
              <Dropdown
                className="filter-dropdown-button"
                disabled={true}
                dropdownRender={() => <ApiUsageHistoryFilterContent />}
                trigger={['click']}
                overlayClassName="filter-drowdown"
              >
                <Badge count={0}>
                  <IconButton icon={<FilterIcon height={20} />} disabled={true} />
                </Badge>
              </Dropdown>
              <RefreshButton doRefetch={refetchData} setIsLoading={setIsRefetchLoading} />
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default APIMonthlyUsageHistoryFilter;
