import React, { ReactNode, useEffect } from 'react';
import { Form, Modal } from 'antd';
import CheckCircleIcon from '../../svgs/CheckCircleIcon';
import Text from '../Text';
import SecondaryButton from '../buttons/SecondaryButton';
import { APIManagementRedemptionCodeI } from '../../interfaces/apiManagement';
import { DateFormat } from '../../utils/global';
import SizeBox from '../SizeBox';

type Props = {
  data?: APIManagementRedemptionCodeI;
  isModalVisible: boolean;
  setIsModalVisible: (_: boolean) => void;
  setIncreaseApiUsageOpen: (_: boolean) => void;
};

const ApiRedeemptionSuccessModal: React.FC<Props> = ({
  isModalVisible,
  data,
  setIsModalVisible,
  setIncreaseApiUsageOpen,
}) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    setIsModalVisible(false);
    setIncreaseApiUsageOpen(false);
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data]);

  const renderView = (label: string, value: ReactNode) => {
    return (
      <>
        <div className="tw-flex tw-flex-col">
          <Text variant="bodyLg" className="tw-block tw-text-grey-900">
            {label}
          </Text>
          <Text variant="bodyLgB" className="tw-block tw-text-grey-900">
            {value}
          </Text>
        </div>
      </>
    );
  };

  return (
    <Modal open={isModalVisible} onOk={handleCancel} onCancel={handleCancel} footer={null} width={555}>
      <div className="tw-flex tw-flex-col tw-items-center tw tw-py-4 tw-px-4">
        <CheckCircleIcon width={48} height={48} color="#0A7A08" />
        <Text variant="h3" className="tw-mt-2 tw-flex tw-flex-col tw-items-center">
          <span className="tw-block">You have successfully increased </span>
          <span>Total Monthly API Usage!</span>
        </Text>
      </div>
      <div className="notice tw-mb-8 tw-text-grey-700">
        <div>
          <Text variant="bodyLg" className="tw-block tw-text-grey-800">
            Your Monthly API Usage is upgraded as following:
          </Text>
          <div className="tw-border-primary tw-rounded tw-bg-grey-200 tw-p-4 tw-mb-3">
            {renderView('Partner', data?.partner.displayName)}
          </div>
          <div className="tw-border-primary tw-rounded tw-bg-grey-200 tw-p-4 tw-gap-6">
            {renderView('New Monthly API Calls Usage', `100 → ${data?.newLimit}`)}
            <SizeBox height={24} />
            {renderView('Validity', `${DateFormat(data?.startDate)} - ${DateFormat(data?.endDate)}`)}
          </div>
          <SecondaryButton
            label="Close"
            className="tw-w-full tw-bg-white !tw-border-grey-300 tw-text-grey-900 tw-mt-9"
            onClick={handleCancel}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ApiRedeemptionSuccessModal;
