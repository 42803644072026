import React, { useMemo, useState } from 'react';
import Text from '../Text';
import Icon from '../Icon';
import TooltipIcon from '../buttons/TooltipIcon';
import SecondaryButton from '../buttons/SecondaryButton';
import { Notice } from '../Notice';
import { Button, Tooltip } from 'antd';
import { useQuery } from 'react-query';
import { getApiManagementSummary } from '../../apis/apiManagement';
import { displayStartDateOfNextMonth, displayStartEndDateOfMonth } from '../../utils/global';
import ApiUsageIncreaseUsageModal from './IncreaseUsageModal';

const apiKeyTooltip =
  'Partner is granted maximum of 5 API keys. Once you reach your quota limit, you will have to delete an existing API Key to create a new key';
const totalMonthlyUsageTooltip =
  'Once you exceed your quota limit, all connections to CircularONE API will be paused until your next month’s reset. You will be notified when you are about to reach the limit';

type Props = {
  setCurrentTab: (_: string) => void;
};

const APISummaryTab: React.FC<Props> = ({ setCurrentTab }) => {
  const [isIncreaseModalOpen, setIsIncreaseModalOpen] = useState<boolean>(false);
  const { data: apiSummary } = useQuery(['api-management-summary', 'details', {}], () => getApiManagementSummary());

  const apiKeys = useMemo(() => {
    const apiKeys = apiSummary?.apiKeys;
    return {
      activeKeys: apiKeys?.totalActiveKeys ?? 0,
      totalLimit: apiKeys?.totalLimit ?? 0,
    };
  }, [apiSummary]);

  const apiTotalMonthlyUsage = useMemo(() => {
    const item = apiSummary?.totalMonthlyUsage;
    return {
      totalUsed: item?.totalUsed ?? 0,
      totalLimit: item?.totalLimit ?? 0,
      isAlmostFull: item?.totalUsed ?? 0 >= (item?.totalLimit ?? 0) - 10,
    };
  }, [apiSummary]);

  const upgradeTooltip = `Upgraded Partnership is granted ${apiTotalMonthlyUsage.totalLimit} API Calls per month, valid from <b>${displayStartEndDateOfMonth()}</b>`;

  const onIncreaseUsageClick = () => {
    setIsIncreaseModalOpen(true);
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-6">
      <div className="tw-bg-white tw-border-primary tw-rounded-[10px] tw-p-6 tw-gap-3 tw-flex-col tw-flex">
        <span className="tw-flex tw-items-center tw-gap-3">
          <Text variant="h3">API Keys</Text>
          <TooltipIcon buttonClassName="tw-w-5 tw-h-5" text={apiKeyTooltip} />
          <Button onClick={() => setCurrentTab('2')} className="tw-border-none tw-bg-inherit tw-p-0">
            <Icon name="icon-chevron_right" />
          </Button>
        </span>
        <Text variant="h1">
          <span style={{ color: apiKeys.activeKeys !== apiKeys.totalLimit ? '#191919' : '#B13939' }}>
            {apiKeys.activeKeys}
          </span>{' '}
          / 5
        </Text>
        <div className="tw-relative tw-w-full tw-h-4 tw-bg-grey-400 tw-rounded-full">
          <span
            className="tw-absolute tw-left-0 tw-top-0 tw-rounded-full tw-h-full"
            style={{
              width: apiKeys.activeKeys !== 0 ? `${(apiKeys.activeKeys / apiKeys.totalLimit) * 100}%` : '0%',
              background: apiKeys.activeKeys !== apiKeys.totalLimit ? '#0a7a08' : '#B13939',
            }}
          />
        </div>
        {apiKeys.activeKeys === apiKeys.totalLimit && (
          <Notice
            type="warning"
            message="Maximum 5 API keys generated reached!"
            description="You will have to delete an existing API Key to create a new key."
            closable={false}
          />
        )}
      </div>
      <div className="tw-bg-white tw-border-primary tw-rounded-[10px] tw-p-6 tw-gap-3 tw-flex tw-flex-col">
        <div>
          <span className="tw-flex tw-items-center tw-gap-3">
            <Text variant="h3">Total Monthly Usage</Text>
            <TooltipIcon buttonClassName="tw-w-5 tw-h-5" text={totalMonthlyUsageTooltip} />
            <Button onClick={() => setCurrentTab('3')} className="tw-border-none tw-bg-inherit tw-p-0">
              <Icon name="icon-chevron_right" />
            </Button>
          </span>
          <Text variant="labelMd" className="!tw-font-normal tw-mt-1">
            {displayStartEndDateOfMonth()}
          </Text>
        </div>
        <div className="tw-flex tw-justify-between sm:tw-flex-col sm:tw-gap-3">
          <div className="tw-flex tw-items-center tw-gap-3">
            <Text variant="h1">
              <span
                style={{
                  color: apiTotalMonthlyUsage.totalUsed !== apiTotalMonthlyUsage.totalLimit ? '#191919' : '#B13939',
                }}
              >
                {apiTotalMonthlyUsage.totalUsed}
              </span>{' '}
              / {apiTotalMonthlyUsage.totalLimit}
            </Text>
            {apiTotalMonthlyUsage.totalLimit > 100 && (
              <Tooltip placement="right" title={<span dangerouslySetInnerHTML={{ __html: upgradeTooltip }} />}>
                <div className="tw-border-primary !tw-border-secondary-200 tw-rounded tw-inline-flex tw-items-center tw-px-2 tw-py-1 tw-cursor-pointer">
                  <Icon name="icon-star" className="tw-text-secondary-500" size={12} />
                  <Text variant="labelMd" className="tw-text-secondary-500 tw-ml-1">
                    Upgraded
                  </Text>
                </div>
              </Tooltip>
            )}
          </div>
          <SecondaryButton
            label="Increase Usage"
            icon={<Icon name="icon-unarchive" />}
            onClick={onIncreaseUsageClick}
          />
        </div>
        <div className="tw-relative tw-w-full tw-h-4 tw-bg-grey-400 tw-rounded-full">
          <span
            className="tw-absolute tw-left-0 tw-top-0 tw-rounded-full tw-h-full"
            style={{
              width:
                apiTotalMonthlyUsage.totalUsed !== 0
                  ? `${(apiTotalMonthlyUsage.totalUsed / apiTotalMonthlyUsage.totalLimit) * 100}%`
                  : '0%',
              background: apiTotalMonthlyUsage.isAlmostFull ? '#B13939' : '#0a7a08',
            }}
          />
        </div>
        <Text variant="bodyMd">
          API usage will be reset monthly. Next reset is{' '}
          <span className="tw-font-bold">{displayStartDateOfNextMonth()}</span>
        </Text>
        {apiTotalMonthlyUsage.totalUsed !== apiTotalMonthlyUsage.totalLimit && apiTotalMonthlyUsage.isAlmostFull && (
          <Notice
            type="warning"
            message="Total Monthly Usage is about to exceed!"
            description="Increase your API monthly usage so that your usage is not interrupted."
            closable={false}
          />
        )}
        {apiTotalMonthlyUsage.totalUsed === apiTotalMonthlyUsage.totalLimit && (
          <Notice
            type="warning"
            message="Total Monthly Usage has exceeded! All connections to CircularONE API are paused!"
            description="Increase your API monthly usage so that your usage is not interrupted."
            closable={false}
          />
        )}
      </div>
      <ApiUsageIncreaseUsageModal isModalVisible={isIncreaseModalOpen} setIsModalVisible={setIsIncreaseModalOpen} />
    </div>
  );
};

export default APISummaryTab;
