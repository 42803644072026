import { Checkbox, Form, Select } from 'antd';
import React, { useEffect } from 'react';
import PrimaryButton from '../../buttons/PrimaryButton';
import './FilterContent.scss';
import { HardwareSearchParamsI } from '../../../interfaces/hardware';
import Text from '../../Text';
import SecondaryButton from '../../buttons/SecondaryButton';
import { CommonStatus } from '../../CommonStatus';
import { convertToHardwareType } from '../../../utils/hardware';
import { useQuery } from 'react-query';
import { getHardwareSitesList } from '../../../apis/hardwares';
import SelectAllCheckbox from '../../SelectAllCheckbox';
import { SiteSearchParamsI } from '../../../interfaces/site';
import ProductTraceStatus from '../../ProductTrace/Status';
import { ProductTraceReportTypeT } from '../../../interfaces/productTrace';

type FormParams = {
  search: string;
  devId: string;
  status: string[];
  condition: string[];
  type: string[];
  productTraceStatus: string[];
  siteIds: string[];
};

const siteQueryParams: SiteSearchParamsI = {
  orderBy: 'displayName',
  orderType: 'desc',
};

type Props = {
  queryParams: HardwareSearchParamsI;
  onChange(newParams: Partial<HardwareSearchParamsI>): void;
  pageName?: string;
};

const HardwareFilterContent: React.FC<Props> = ({ queryParams, onChange, pageName }) => {
  const [form] = Form.useForm();

  const handleResetFilter = () => {
    form.resetFields();
    onChange({ filter: {} });
  };

  const handleOnFinish = (values: FormParams) => {
    onChange({
      filter: {
        status: values?.status,
        type: values?.type,
        condition: values?.condition,
        siteIds: values?.siteIds,
        productTraceStatus: values.productTraceStatus,
      },
      search: values?.search,
    });
  };

  const { data: { sites = [] } = {} } = useQuery(
    ['sites', 'list', siteQueryParams],
    () => getHardwareSitesList(siteQueryParams),
    {
      select({ data: { sites, total } }) {
        return { sites, total };
      },
    },
  );

  const typeOptions = ['ecoplant', 'ecotote', 'smartpour'];
  const statusOptions = ['active', 'idle', 'inactive'];
  const conditionOptions = ['faulty', 'good'];
  const typePageNames = ['tracking', 'registrations'];
  const statusPageNames = ['tracking'];
  const conditionPageNames = ['tracking'];
  const productTraceOptions: ProductTraceReportTypeT[] = ['productRecall', 'onHold'];
  const productTracePageNames = ['tracking'];
  useEffect(() => {
    form.setFieldsValue({
      type: queryParams.filter?.type,
      status: queryParams.filter?.status,
      productTraceStatus: queryParams.filter?.productTraceStatus,
      condition: queryParams.filter?.condition,
      siteIds: Array.isArray(queryParams.filter?.siteIds)
        ? queryParams.filter?.siteIds?.map((site: string) => parseInt(site))
        : '',
    });
  }, [queryParams]);

  return (
    <div className="component-hardware-filter-content-container partner-filter-content">
      <Text variant="h3">Filter</Text>
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        layout="vertical"
        className="tw-max-h-[580px]"
      >
        <div className="form-container tw-flex tw-flex-col tw-gap-3 tw-justify-between">
          <div className={`form-content tw-max-h-[450px] tw-overflow-auto ${pageName === 'tracking' && 'tw-pr-4'}`}>
            <div className="status-section">
              {typePageNames.includes(pageName ?? '') && (
                <>
                  <Form.Item name="type">
                    <SelectAllCheckbox form={form} plainOptions={typeOptions} name="type" sectionTitle="Type">
                      {typeOptions.map((item) => (
                        <Checkbox key={item} value={item}>
                          {convertToHardwareType(item)}
                        </Checkbox>
                      ))}
                    </SelectAllCheckbox>
                  </Form.Item>
                </>
              )}
              {statusPageNames.includes(pageName ?? '') && (
                <>
                  <Form.Item name="status">
                    <SelectAllCheckbox form={form} plainOptions={statusOptions} name="status" sectionTitle="Status">
                      {statusOptions.map((status) => (
                        <Checkbox key={status} value={status}>
                          <CommonStatus status={status} />
                        </Checkbox>
                      ))}
                    </SelectAllCheckbox>
                  </Form.Item>
                </>
              )}
              {conditionPageNames.includes(pageName ?? '') && (
                <>
                  <Form.Item name="condition">
                    <SelectAllCheckbox
                      form={form}
                      plainOptions={conditionOptions}
                      name="condition"
                      sectionTitle="Condition"
                    >
                      {conditionOptions.map((condition: string) => (
                        <Checkbox key={condition} value={condition} style={{ textTransform: 'capitalize' }}>
                          <CommonStatus status={condition} />
                        </Checkbox>
                      ))}
                    </SelectAllCheckbox>
                  </Form.Item>
                </>
              )}
              {productTracePageNames.includes(pageName ?? '') && (
                <Form.Item name="productTraceStatus">
                  <SelectAllCheckbox
                    form={form}
                    plainOptions={productTraceOptions}
                    name="productTraceStatus"
                    sectionTitle="Product Trace"
                  >
                    {productTraceOptions.map((item) => {
                      return (
                        <Checkbox key={item} value={item}>
                          <ProductTraceStatus status={item} />
                        </Checkbox>
                      );
                    })}
                  </SelectAllCheckbox>
                </Form.Item>
              )}
              <Text variant="h4" className="tw-mb-3">
                Site
              </Text>
              <Form.Item name="siteIds">
                <Select placeholder="Select sites" showSearch optionFilterProp="label" allowClear mode="multiple">
                  {sites
                    .slice()
                    .sort((a, b) => a.displayName.localeCompare(b.displayName))
                    .map((item) => (
                      <Select.Option key={item.id} value={item.id} label={item.displayName}>
                        {item.displayName}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-3 tw-pb-4">
            <PrimaryButton label="Apply Filter" htmlType="submit" />
            <SecondaryButton label="Reset Filter" onClick={handleResetFilter} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default HardwareFilterContent;
