import { DatePicker, Divider, Form, FormInstance, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import Text from '../../Text';
import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';
import EInputNumber from '../../inputs/EInputNumber';
import { APIRedemptionI, GenerateRedemptionCodeRequestT } from '../../../interfaces/apiRedemption';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { getPartnersList } from '../../../apis/partners';
import { PartnerI } from '../../../interfaces/partner';
import { convertDayJSToISOString } from '../../../utils/global';

type Props = {
  form: FormInstance;
  data?: APIRedemptionI;
  handleSubmit(_: any): void;
  handleCancel: () => void;
};

const GenerateRedemptionCodeForm: React.FC<Props> = ({ form, data, handleSubmit, handleCancel }) => {
  const [isnewLimitError, setIsnewLimitError] = useState<boolean>(false);
  const [isvalidityDurationError, setIsvalidityDurationError] = useState<boolean>(false);

  const { data: { partners = [] } = {}, isFetching: isPartnersFetching } = useQuery(
    ['platform-partners', 'list', {}],
    () => getPartnersList({}),
    {
      select({ data: { partners, total } }) {
        return { partners, total };
      },
    },
  );

  const onCancel = () => {
    form.resetFields();
    setIsnewLimitError(false);
    setIsvalidityDurationError(false);
    handleCancel();
  };

  const onFinish = (values: GenerateRedemptionCodeRequestT) => {
    const newValues = values;
    if (values.expiryDate) {
      newValues.expiryDate = convertDayJSToISOString(dayjs(values.expiryDate)).toISOString();
    }
    handleSubmit(values);
  };

  useEffect(() => {
    form.setFieldsValue({
      newLimit: data?.newLimit,
      duration: data?.duration,
      expiryDate: data?.expiryDate ? dayjs(data.expiryDate) : undefined,
      validPartnerId: data?.validPartnerId,
    });
  }, [data]);

  return (
    <>
      <Text variant="h2">{data ? 'Edit' : 'Generate'} Redemption Code</Text>
      <Form form={form} onFinish={onFinish} scrollToFirstError layout="vertical">
        <div className="form-container">
          <div className="form-content">
            <Form.Item
              label="New Monthly API Usage"
              name="newLimit"
              rules={[
                { required: true, message: 'This field is required' },
                {
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.resolve();
                    } else if (value < 100) {
                      setIsnewLimitError(true);
                      return Promise.reject('Invalid Input. Value > 100');
                    } else {
                      setIsnewLimitError(false);
                      return Promise.resolve();
                    }
                  },
                },
              ]}
              className="tw-w-full !tw-mb-8"
              validateStatus={isnewLimitError ? 'error' : ''}
              help={isnewLimitError ? 'Invalid Input. Value > 100' : 'Value > 100'}
            >
              <EInputNumber min={null} />
            </Form.Item>
            <div className="tw-border-primary tw-rounded-lg tw-bg-grey-100 tw-p-3">
              <Form.Item
                label="Validity Duration"
                name="duration"
                rules={[
                  { required: true, message: 'This field is required' },
                  {
                    validator: (_, value) => {
                      if (!value && value < 0) {
                        return Promise.resolve();
                      } else if (value < 1) {
                        setIsvalidityDurationError(true);
                        return Promise.reject('Invalid Input. Value > 0');
                      } else {
                        setIsvalidityDurationError(false);
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
                className="tw-w-full !tw-mb-8"
                validateStatus={isvalidityDurationError ? 'error' : ''}
                help={isvalidityDurationError ? 'Invalid Input. Value > 0' : 'Value > 0'}
              >
                <EInputNumber suffix="month(s)" min={null} />
              </Form.Item>
              <div>
                <Text variant="bodyLg">Validity Date of this redemption code is calculated:</Text>
                <ul className="tw-m-0 tw-pl-6">
                  <li>
                    <Text variant="bodyLg">
                      <b>Start Date:</b> the date that user redeem the code
                    </Text>
                  </li>
                  <li>
                    <Text variant="bodyLg">
                      <b>End Date:</b> based on the selected Validity Duration
                    </Text>
                  </li>
                </ul>
              </div>
            </div>
            <Divider className="tw-my-6" />
            <Form.Item
              label="Code Expiry Date (Optional)"
              name="expiryDate"
              className="tw-w-full"
              extra="Required to be later than the Date of Today"
            >
              <DatePicker
                placeholder="Select a date"
                format="DD MMM YYYY"
                className="tw-w-full"
                disabledDate={(current) => current && current < dayjs().startOf('day')}
              />
            </Form.Item>
            <Divider className="tw-my-6" />
            <Form.Item label="Partner Entity (Optional)" name="validPartnerId" className="tw-w-full">
              <Select placeholder="Select a Partner" loading={isPartnersFetching} style={{ width: '100%' }}>
                {partners.map((item: PartnerI) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.displayName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <PrimaryButton label={data ? 'Save' : 'Generate'} htmlType="submit" className="tw-w-full tw-mb-3" />
            <SecondaryButton label="Cancel" className="tw-w-full" onClick={onCancel} />
          </div>
        </div>
      </Form>
    </>
  );
};

export default GenerateRedemptionCodeForm;
