import { Form, Spin, message } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { createProduct } from '../../apis/products';
import { ProductI } from '../../interfaces/product';
import ProductForm from '../../components/forms/product/Form';
import { CreateUpdateTopSection } from '../../components/shared/CreateUpdateTopSection';
import Text from '../../components/Text';
import NonClosableModal from '../../components/NonClosableModal';
import CheckCircleIcon from '../../svgs/CheckCircleIcon';
import SuccessSubmittedItem from '../../components/shared/SuccessSubmittedItem';
import { useNavigate } from 'react-router-dom';
import Icon from '../../components/Icon';

const ProductNewPage: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [sku, setSku] = useState<string>('');
  const [successData, setSuccessData] = useState<ProductI>();

  const { mutate: createMutate, isLoading: isPageLoading } = useMutation(createProduct, {
    onSuccess: async (data) => {
      queryClient.invalidateQueries({
        queryKey: ['products', 'list'],
      });
      setSku(data.sku);
      setIsModalVisible(true);
      setSuccessData(data);
    },
    onError: () => {
      message.error('Create product failed! Please try again');
    },
  });

  const handleSubmit = async (values: Partial<ProductI>) => {
    createMutate(values);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleAddProductGroup = () => {
    if (successData) {
      localStorage.setItem('productCreated', JSON.stringify(successData));
      navigate('/product-groups/new');
    }
  };

  return (
    <Spin spinning={isPageLoading}>
      <div className="tw-p-4">
        <CreateUpdateTopSection label="Add Product" labelButton="Create" form={form} />
        <ProductForm form={form} handleSubmit={handleSubmit} />
      </div>
      <NonClosableModal isVisible={isModalVisible} handleCancel={handleModalCancel} width={506}>
        <div className="tw-text-center tw-py-4 tw-px-4">
          <div className="tw-flex tw-flex-col tw-items-center">
            <CheckCircleIcon width={48} height={48} color="#0A7A08" />
            <Text variant="h3" className="tw-mt-2 tw-mb-3">
              You have successfully submitted!
            </Text>
          </div>
          <div className="notice tw-mb-8 tw-text-grey-700">
            <Text variant="bodyMd" className="!tw-block">
              Few actions are available for this entry.
            </Text>
            <Text variant="bodyMd" className="!tw-block">
              You can choose the options below
            </Text>
          </div>
          <div className="tw-flex tw-gap-3">
            <SuccessSubmittedItem
              url={`/products/${sku}`}
              title="View the Product"
              description={['View the Product you have just created']}
              layout="horizontal"
              icon={<Icon name="icon-wine_bar" size={40} className="tw-text-primary-800" />}
            />
            <SuccessSubmittedItem
              onClick={handleAddProductGroup}
              title="Product Grouping"
              description={['Create a product group from created product(s)']}
              layout="horizontal"
              icon={<Icon name="icon-group_wine" size={40} className="tw-text-primary-800" />}
            />
          </div>
        </div>
      </NonClosableModal>
    </Spin>
  );
};

export default ProductNewPage;
