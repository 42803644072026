import { Form, Input } from 'antd';
import React from 'react';
import Text from '../../Text';
import PrimaryButton from '../../buttons/PrimaryButton';

type Props = {
  handleSubmit(_: any): void;
};

const GenerateKeyName: React.FC<Props> = ({ handleSubmit }) => {
  const [form] = Form.useForm();
  return (
    <>
      <Text variant="h2">Generate API Key</Text>
      <Form form={form} onFinish={handleSubmit} scrollToFirstError autoComplete="off" layout="vertical">
        <div className="form-container">
          <div className="form-content">
            <Form.Item label="API Key Name" name="name" rules={[{ required: true, message: 'This field is required' }]}>
              <Input placeholder="Type API Key Name" />
            </Form.Item>
            <PrimaryButton label="Generate" htmlType="submit" className="tw-w-full" />
          </div>
        </div>
      </Form>
    </>
  );
};

export default GenerateKeyName;
